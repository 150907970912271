import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { registerDonador,fetchProjects } from "../actions/ProjectActions";
import Layout from "../Layout/Layout";
import { Helmet } from "react-helmet";
import swal from 'sweetalert';


function Padrinazgoform(props) {
  const [id_proyecto, setId_proyecto] = useState({});
  const [nombre, setNombre] = useState("");
  const [documento, setDocumento] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [motivo, setMotivo] = useState("p");
  const [anonimo, setAnonimo] = useState("0");
  const [ projects, setProjects] = useState({});
  
  const userRegisterDonador = useSelector((state) => state.userRegisterDonador);
  const { loading, userInfo, error } = userRegisterDonador;
  const dispatch = useDispatch();
  const inputRef = useRef();



  // const redirect = props.location.search
  //   ? props.location.search.split("=")[1]
  //   : "/";
  useEffect(() => {
    async function projects() {
      const projects = await fetchProjects(props.match.params.id);
  
      setProjects(projects.data);
    }
    
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    swal("Enviado!", "Puedes seguir con el pago", "success");
    dispatch(
      registerDonador(
        props.match.params.id,
        nombre,
        documento,
        email,
        telefono,
        motivo,
        anonimo
      )
    );
  };

  return (
    <Layout>
      <Helmet>
        <title>Datos para padrinazgo</title>

        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="%PUBLIC_URL%imagenes/logof.png" />
        <html lang="es" />
        <meta name="theme-color" content="#000000" />
        <meta name="robots" content="index, nofollow"></meta>
      </Helmet>
      <section className="login">
        <h1 className="centrar">FORMULARIO DE PADRINAZGO</h1>
        <form onSubmit={submitHandler} className="container-login-form">
          <div className="datos-forms">
            <h2>DATOS PERSONALES PARA FACTURACIÓN</h2>
            {loading && <div>Loading...</div>}
            {error && <div>{error}</div>}

            <input
              ref={inputRef}
              type="hidden"
              id="id_proyecto"
              name="id_proyecto"
              value={props.match.params.id}
              onChange={(e) => setId_proyecto(e.target.value)}
            />

            <input
              placeholder="Nombre y Apellido"
              className="input"
              type="nombre"
              name="nombre"
              id="nombre"
              onChange={(e) => setNombre(e.target.value)}
              required
            ></input>
            <input
              placeholder="CI,RUC O PASAPORTE"
              className="input"
              type="documento"
              name="nombre"
              id="nombre"
              onChange={(e) => setDocumento(e.target.value)}
              required
            ></input>

            <input
              placeholder="Correo electrónico"
              className="input"
              type="email"
              name="email"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
              required
            ></input>

            <input
              placeholder="Celular o teléfono"
              className="input"
              type="telefono"
              id="telefono"
              name="telefono"
              onChange={(e) => setTelefono(e.target.value)}
              required
            ></input>

            <input
              ref={inputRef}
              type="hidden"
              id="motivo"
              name="motivo"
              value="padrinazgo"
              onChange={(e) => setMotivo(e.target.value)}
            />
            <div className="idiomas">
              <label className="username"></label>
              <label>
                {" "}
                <p className="checkbox">
                  {" "}
                  <input
                    type="checkbox"
                    id="anonimo"
                    value="1"
                    name="anonimo"
                    onChange={(e) => setAnonimo(e.target.value)}
                  />{" "}
                  Quiero apadrinar de forma anónima
                </p>
              </label>
              <p className="datos-a">
               
               Los datos proveídos serán utilizados para la emisión de una
               factura legal por el valor del aporte realizado al proyecto.
             </p>
            </div>
            <button className="button-red-forms">Siguiente</button>
            <br />
            <br />
          </div>
        </form>
      </section>
    </Layout>
  );
}
export default Padrinazgoform;
