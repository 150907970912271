  
import React, { Component } from "react";
import { connect } from "react-redux";
import { filterProducts, sortProducts } from "../actions/ProductActions";
import Buscador from '../components/Buscador'

class Filter extends Component {
  render() {
    // console.log(this.props)

    return !this.props.filteredProducts ? (
      <div>Loading...</div>
    ) : (
      <div className="filter">
        
          {" "}

          <select
              value={this.props.products.category}
              onChange={(e) =>
                this.props.filterProducts(this.props.products, e.target.value)
              }
              className="filter-1"
            >
              <option value="">Categorías</option>
              {this.props.products.categories.map((product) => (
            
            <option key={product.id_categoria} value={product.id_categoria}>{product.name}</option>
            
            ))}
          
          </select>
        
        <div className="filter-buscador">
          <Buscador />
        </div>
      </div>
    );
  }
}
export default connect(
  (state) => ({
    category: state.products.category,
    sort: state.products.sort,
    products: state.products.items,
    filteredProducts: state.products.filteredItems,
  }),
  {
    filterProducts,
    sortProducts,
  }
)(Filter);