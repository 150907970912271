import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { facebook } from "../actions/userActions";
import { useHistory, Redirect } from "react-router-dom";
import "../Shopping.css";

import GoogleLogin from "react-google-login";

function Gmail(props) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [client_id, setId] = useState("");
  const [user, setUser] = useState(null);
    const [accessToken, setAccessToken] = useState(null);

  const dispatch = useDispatch();

  const userSigninFacebook = useSelector((state) => state.userSigninFacebook);

  const { userInfo } = userSigninFacebook;

  const history = useHistory();

  const [redirctTo, setRedirctTo] = useState(false);

  useEffect(() => {
    if (userInfo) {
      //window.location.replace('/');
      // history.push("/");
      // setRedirctTo(true);
      // const redirect =  "/";
      // Location.href=redirect
    }
    return () => {};
  }, [userInfo]);

  // if (redirctTo) {
  //   return <Redirect to="/" />;
  // } else {

  // }

  const submitHandler = (e) => {
    e.preventDefault();
    //dispatch(facebook(email, id,name));
  };

  const responseGoogle = ({ profileObj }) => {

    console.log(profileObj.email);
    console.log(profileObj.googleId);
    console.log(profileObj.name);


    dispatch(facebook(profileObj.email, profileObj.googleId, profileObj.name));
  };

  return (
    <div>
      <form onSubmit={submitHandler}>
        <GoogleLogin
          clientId="296705845506-kkol0ivt7vk74iqmjcttbq908v9v8n3q.apps.googleusercontent.com"
          render={(renderProps) => (
            <button onClick={renderProps.onClick} className="button-azul-redes">
              <img
                src="/imagenes/gmail.png"
                className="redes-sociales-gmail"
                alt="Iniciar sesión con Google"
              />{" "}
              Continuar con Gmail
            </button>
          )}
          buttonText="Login"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={"single_host_origin"}
        />
      </form>
    </div>
  );
}
export default Gmail;
