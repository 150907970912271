import React from "react";
import { Link } from "react-router-dom";
import "../Shopping.css";
import { connect } from "react-redux";
import Cart from "../components/Cart";
import Boton from "../Layout/Boton";
import { removeFromCart } from "../actions/CartActions";
import ProfileScreen from "../screens/ProfileScreen";
import ProfileScreen2 from "../screens/ProfileScreen2";
import MiEspacio from "../Layout/MiEspacio";
import MisDatos from "./MisDatos";
import MisDatos2 from "./MisDatos2";
import LayoutUsu from "../Layout/LayoutUsu"
import Alertacont from "../pages/Alertacont";

class Navigation extends React.Component {
  render() {
    const openMenu = () => {
      document.querySelector(".sidebar").classList.add("open");
    };
    const closeMenu = () => {
      document.querySelector(".sidebar").classList.remove("open");
    };
    const openMenuCart = () => {
      document.querySelector(".sidebar-CART").classList.add("open");
    };
    const closeMenuCart = () => {
      document.querySelector(".sidebar-CART").classList.remove("open");
    };
    const { cartItems } = this.props;

    return (
      <div>
        <div className="nav3">
          <MiEspacio />
          <div className="logo-nav-2-redes">
            <a href="https://www.instagram.com/futboltotalpy/?igshid=vyjsip5irtbe">
              <img
                src="/imagenes/redescarrito-105.png"
                className="redes-sociales"
                alt="instagram"
              />
            </a>

            <a href="https://api.whatsapp.com/send?phone=595986704004">
              <img
                src="/imagenes/redescarrito-107.png"
                className="redes-sociales"
                alt="whatsapp"
              />
            </a>

            <a href="https://www.facebook.com/futboltotalpy?_rdc=1&_rdr">
              <img
                src="/imagenes/redescarrito-106.png"
                className="redes-sociales"
                alt="facebook"
              />
            </a>
          </div>
        </div>
        <nav className="nav1">
          <div className="contenedor-nav">
            <div className="logo-nav-1">
              <Boton />
            </div>
            <div className="logo-nav">
              <Link to="/">
                <img
                  src="/imagenes/logof.png"
                  className="icon-logo"
                  alt="Futbol total femenino y masculino"
                />
              </Link>
            </div>
            <div className="logo-nav-2">
              <button className="icono-cart" onClick={openMenuCart}>
                {cartItems.length === 0 ? (
                  <div className="cart cart-header">
                    <img
                      src="/imagenes/redescarrito-108.png"
                      className="carrito-1"
                      alt="carrito"
                    />
                    <span>0</span>
                  </div>
                ) : (
                  <div className="cart cart-header">
                    <img
                      src="/imagenes/redescarrito-108.png"
                      className="carrito-1"
                      alt="carrito de compras"
                    />
                    {cartItems.length}{" "}
                  </div>
                )}
              </button>

              <button className="icono" onClick={openMenu}>
                &#9776;
              </button>
            </div>
          </div>
        </nav>

        <nav className="nav2">
          <div className="contenedor-nav">
          <div className="logo-nav-2-carrito">

            <ProfileScreen />
            <MisDatos2 />
            <Alertacont/>
</div>

            <div className="logo-nav-700">
              <Link to="/">
                <img
                  src="/imagenes/logof.png"
                  className="icon-logo"
                  alt="Fútbol total masculino y femenino"
                />
              </Link>
            </div>

            <div className="logo-nav-2-carrito">

            <Link className="lista-sidebar-700" to="/conocenos">
              {" "}
              <p>¿Quienes somos?</p>
            </Link>
            <Link className="lista-sidebar-700" to="/contacto">
              {" "}
              <p>Contacto</p>
            </Link>
              <button className="icono-cart-700" onClick={openMenuCart}>
                {cartItems.length === 0 ? (
                  <div className="cart cart-header">
                    <img
                      src="/imagenes/redescarrito-108.png"
                      className="carrito-1"
                      alt="carrito"
                    />
                    <span>0</span>
                  </div>
                ) : (
                  <div className="cart cart-header">
                    <img
                      src="/imagenes/redescarrito-108.png"
                      className="carrito-1"
                      alt="carrito"
                    />
                    {cartItems.length}{" "}
                  </div>
                )}
              </button>
            </div>
          </div>
        </nav>

        <div className="sidebar-CART">
          <button className="close-modal-cart" onClick={closeMenuCart}>
            &times;
          </button>

          <Cart />
          <Link to="/carrito">
            <button className="button-red-carrito">Proceder al pago</button>
          </Link>
        </div>

        <div className="sidebar">
          <ul className="categories">
            <button className="close-modal" onClick={closeMenu}>
              &times;
            </button>
            





            <div className="lista-sidebar">

              <ProfileScreen2 />
            </div>
            
<MisDatos />

           
            <LayoutUsu/>
            
            <hr/>
            <div className="lista-sidebar">
              <Link to="/contacto">
                <img
                  src="/imagenes/icc-108.png"
                  className="redes-s-salir"
                  alt="contacto"
                />
                <p className="menu"> Contacto</p>{" "}
              </Link>
            </div>
            <br />
            <hr />
            <div className="lista-sidebar">
              <Link to="/conocenos">
                <img
                  src="/imagenes/quienes-somos.png"
                  className="redes-s-salir"
                  alt="quienes somos"
                />
                <p className="menu"> Quienes somos</p>{" "}
              </Link>
            </div>
            <br />
            <hr />
          </ul>
        </div>
      </div>
    );
  }
}
export default connect(
  (state) => ({
    cartItems: state.cart.cartItems,
  }),
  { removeFromCart }
)(Navigation);
