import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { signin } from "../actions/userActions";
import "../Shopping.css";
import Facebook from "../components/Facebook";
import Gmail from "../components/Gmail";
import { Helmet } from "react-helmet";

function SigninScreen(props) {
  const [email, setEmail] = useState("");
  const [contrasena, setContrasena] = useState("");
  const { register, handleSubmit } = useForm();

  const dispatch = useDispatch();

  const redirect = props.location.search
    ? props.location.search.split("=")[1]
    : "/";
  const userSignin = useSelector((state) => state.userSignin);

  const { loading, userInfo, error } = userSignin;

  useEffect(() => {
    if (userInfo) {
      props.history.push(redirect);
    }
    return () => {};
  }, [userInfo]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(signin(email, contrasena));
  };

  return (
    <div className="login">
      <Helmet>
      <title>Iniciar sesión</title>

        <meta
          name="description"
          content="Inicia sesion y que tu fútbol sea total"
        />

        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="%PUBLIC_URL%imagenes/logof.png" />
        <html lang="es" />
        <meta name="theme-color" content="#000000" />
        <meta name="robots" content="index, nofollow"></meta>
      </Helmet>
      <Link to="/">
        {" "}
        <button className="close-modal">&times;</button>
      </Link>
      <hr />
      <br />

      <Link to="/">
        <img src="/imagenes/logof.png" className="App-logo" alt="Fútbol total" />
      </Link>
      <br />
      <br />
      <br />
      <div className="container-login-form">
        <form onSubmit={submitHandler}>
          <h5>Iniciar sesión</h5>
          <div className="form-container">
            <div>
              {loading && <p>Loading...</p>}
              {error && <div className="error">{error}</div>}
            </div>
            <div className="datos-forms">
              <input
                placeholder="Correo electrónico"
                className="input"
                type="email"
                name="email"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                required
              ></input>

              <input
                placeholder="Contraseña"
                className="input"
                type="password"
                id="contresana"
                name="contrasena"
                onChange={(e) => setContrasena(e.target.value)}
                required
              ></input>
            </div>
            <div className="centrar">
              <button className="button-red-forms">Iniciar sesión </button>
              <br />
              <br />
              <Link
                to={
                  redirect === "/"
                    ? "register"
                    : "register?redirect=" + redirect
                }
              >
                <button className="button-transparente-redes">
                  Registrate
                </button>
              </Link>
              <br />
              <br />
              <p>o</p>
              <br />
              <br />
            </div>
          </div>
        </form>

         {/* <Facebook />

        <br />  */}

        <Gmail />

        <div className="login-olvidaste">
          <Link className="login-olvidaste" to="/codigo">
            ¿Olvidaste tu Contraseña?
          </Link>
        </div>
      </div>
    </div>
  );
}
export default SigninScreen;
