import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  detailsProduct,
  detailsProduct2,
  saveProductReview,
} from "../actions/ProductActions";
import Rating from "../components/Rating";
import { PRODUCT_REVIEW_SAVE_RESET } from "../constants/productConstants";
import { addToCart } from "../actions/CartActions";
import formatCurrency from "../components/Util";
import Layout from "../Layout/Layout";
import Modal2 from "../components/Modal2";
import ReactPlayer from "react-player";
import { Helmet } from "react-helmet";
import swal from 'sweetalert';

function RatingScreen(props) {
  // cont++;
  // console.log(cont);

  const [qty, setQty] = useState(1);
  const [product, setProduct] = useState({});
  const [comments, setComments] = useState({});
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(0);
  const [id_producto, setId_producto] = useState("");
  const [id_usuario, setId_usuario] = useState("");

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  let productDetails = useSelector((state) => state.productDetails);

  // const { product, loading, error } = productDetails;
  const productReviewSave = useSelector((state) => state.productReviewSave);
  const { success: productSaveSuccess } = productReviewSave;
  const dispatch = useDispatch();
  const inputRef = useRef();
  //const {products}
  useEffect(() => {
    // console.log("hola1");
    async function product() {
      const product = await detailsProduct2(props.match.params.id);

      setProduct(product.data.data.products[0]);
    }
    async function comments() {
      const product = await detailsProduct2(props.match.params.id);

      setComments(product.data);
    }

    if (productSaveSuccess) {
      swal("Enviado!", "Gracias por tu reseña", "success");
      setComment("");
      setRating(0);
      setId_producto(0);
      setId_usuario("");
      dispatch({ type: PRODUCT_REVIEW_SAVE_RESET });
    } else {
      product();
      comments();
    }
  }, [productSaveSuccess, inputRef]);

  const submitHandler = (e) => {
    e.preventDefault();
   

    dispatch(
      saveProductReview(
        comment,
        rating,
        props.match.params.id,
        userInfo.id_usuario
      )
    );
  };


  return (
    <div>
      <Layout>
        <Helmet>
          <title>{product.name}</title>
          <meta name="description" content={product.description} />
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="%PUBLIC_URL%imagenes/logof.png" />
          <html lang="es" />
          <meta name="theme-color" content="#000000" />
          <meta name="robots" content="index, follow"></meta>
        </Helmet>
        {product && (
          <div itemscope itemtype = "https://schema.org/IndividualProduct" itemid = {product.id_producto} >
            <h1 className="centrar">DESCRIPCIÓN</h1>
            <div className="product-details">
              <main>
                <div className="video-contenidos">
                  <ReactPlayer
                    playing={true}
                    width="100%"
                    height="100%"
                    url={product.video}
                    controls
                  />
                </div>
                <div className="product-details-description">
                  <h2>
                    <strong itemprop = "name" >
                      {product.name}
                      <br />
                      Descripción:{" "}
                    </strong>
                  </h2>
                  <div itemprop = "description" className="datos-forms">
                    <p>{product.description}</p>
                  </div>
                  <div className="product-price">
                    <hr />
                    <br/><br/>
                    <h1 className="centrar">RESUMEN</h1>

                    <p className="centrar product-price-details">
                      Total:{formatCurrency(product.price)}
                    </p>

                    <hr />
                    <div>
                    <br/><br/>
                      <h1 className="centrado">
                        PASOS PARA QUE TU FÚTBOL SEA TOTAL
                      </h1>
                      <ol>
                        <li>
                          <p>
                            Presiona el botón comprar o seguí agregando al
                            carrito;
                          </p>
                        </li>
                        <li>
                          <p>
                            Crear tu cuenta, o inicia sesión directamente si ya
                            tienes una;
                          </p>
                        </li>
                        <li>
                          <p>Elije el método de pago de tu preferencia</p>
                        </li>
                        <li>
                          <p>Disfruta del Fútbol Total</p>
                        </li>
                      </ol>
                      <br />
                      <Link to="/signin?redirect=payment">
                        <button
                          
                          onClick={() => dispatch(addToCart(product))}
                          className="button-red-forms"
                        >
                          Comprar
                        </button>
                      </Link>
                      <br />
                      <br />
                      <button
                        className="button-transparente-forms"
                        onClick={() => dispatch(addToCart(product))}
                      >
                        Agregar al carrito
                      </button>
                      <br />
                      <br />
                      <hr />
                      <div className="product-rating">
                        {/* <Link
                        className="link-name"
                        to={"#" + products._id}
                        to={`/product/${products._id}`}
                        onClick={() => this.openModal(products)}
                      > {" "}  */}
                        <Rating
                          value={product.average_rating}
                          text={product.numreviews + " Reseñas"}
                        />
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        )}

        <h2 className="centrar">
          Tu comentario es muy importante para nosotros
        </h2>
        {userInfo ? (
          <form onSubmit={submitHandler}>
            <div className="datos-forms">
              <input
                ref={inputRef}
                type="hidden"
                id="acticuloId"
                name="articuloId"
                value={userInfo.id_usuario}
                onChange={(e) => setId_usuario(e.target.value)}
              />
              <input
                ref={inputRef}
                type="hidden"
                id="acticuloId"
                name="articuloId"
                value={props.match.params.id}
                onChange={(e) => setId_producto(e.target.value)}
              />

              <select
                ref={inputRef}
                className="filter-comentarios"
                name="rating"
                id="rating"
                value={rating}
                onChange={(e) => setRating(e.target.value)}
              >
                <option value="1">1- Malo</option>
                <option value="2">2- Regular</option>
                <option value="3">3- Bueno</option>
                <option value="4">4- Muy bueno</option>
                <option value="5">5- Excelente</option>
              </select>

              <textarea
                ref={inputRef}
                className="comentarios"
                placeholder="Comentarios"
                rows="8"
                cols="35"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>

              <button type="submit" className="button-red-forms">
                Enviar
              </button>
            </div>
          </form>
        ) : (
          <div>
            <Link className="login-olvidaste" to="/signin">
              Inicia sesión para dejarnos tu comentario.
            </Link>
          </div>
        )}
        <br />
        <br />
        <h2 className="centrar">Comentarios</h2>
        <br />

        {Object.keys(comments).length == 0 ? (
          <div className="centrar">
            <p>No hay comentarios</p>
          </div>
        ) : (
          comments.data.comments.map((review) => (
            <div className="reviews-name">
              <div key={review._id}>
                <div>
                  <p>{review.name}</p>
                </div>
                <div>
                  <Rating value={review.rating}></Rating>
                </div>
                <div>{/* <p>{review.createdAt.substring(0, 10)}</p> */}</div>
                <div>
                  <p>{review.comment}</p>
                </div>
              </div>
            </div>
          ))
        )}
      </Layout>
    </div>
  );
}
export default RatingScreen;
