import { FETCH_VIDEOS } from "../Types";
import { URL_SERVER} from '../constants/userConstants'
import Axios from "axios";


export const fetchVideos = (id_usuario) => async (dispatch, getState) => {
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    dispatch({ type: FETCH_VIDEOS, payload: id_usuario });
    const data = await Axios.put(URL_SERVER + `/listVideos/${userInfo.id_usuario}`);
    
    dispatch({ type: FETCH_VIDEOS, payload: data.data });
  } catch (error) {
    dispatch({type: FETCH_VIDEOS, payload: error.message });
  }
  
};