import Axios from "axios";
import { URL_SERVER} from '../constants/userConstants';
import {
  ORDER_CREATE_REQUEST, ORDER_CREATE_SUCCESS, ORDER_CREATE_FAIL,
  ORDER_DETAILS_REQUEST, ORDER_DETAILS_SUCCESS, ORDER_DETAILS_FAIL, ORDER_PAY_REQUEST, ORDER_PAY_SUCCESS, ORDER_PAY_FAIL,
  CART_EMPY
} from "../Types";

export const createOrder = (id_usuario,nombre,documento,email,monto_compra,cartItems) => async (dispatch, getState) => {
  const monto = cartItems.cartItems.reduce((a, c) => a + c.price * c.count, 0)
  
  console.log(cartItems.cartItems)
  //dispatch({type: ORDER_CREATE_SUCCESS, payload: { id_usuario:id_usuario,nombre:nombre,documento:documento,email:email,monto_compra:monto,cartItems:cartItems.cartItems }});
  try {
    const {
      userSignin: { userInfo },
    } = getState();
    const { data } = await Axios.post(URL_SERVER+"/orders", { id_usuario:id_usuario,nombre:nombre,documento:documento,email:email,monto_compra:monto,cartItems:cartItems.cartItems });
    dispatch({ type: ORDER_CREATE_SUCCESS, payload: data.order });
    dispatch({ type: CART_EMPY });
    localStorage.removeItem('cartItems');
  } catch (error) {
    dispatch({
      type: ORDER_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const detailsOrder = (id_usuario) => async (dispatch, getState) => {
  dispatch({ type: ORDER_DETAILS_REQUEST, payload: id_usuario });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    
    const { data } = await Axios.put(URL_SERVER+`/ordersUser/${id_usuario}`,  { id_usuario:id_usuario });
    dispatch({ type: ORDER_DETAILS_SUCCESS, payload: data.data });
    localStorage.setItem('facturacion', JSON.stringify(data.data));
    //console.log("hola",data.data)
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ORDER_DETAILS_FAIL, payload: message });
  }
};
