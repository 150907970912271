import React, { useEffect,useState } from "react";
import {BrowserRouter} from "react-router"
import { useDispatch, useSelector } from "react-redux";
import formatCurrency from "../components/Util";
import { createOrder } from "../actions/orderActions";
import { ORDER_CREATE_RESET } from "../Types";
import "../Shopping.css";
import { Helmet } from "react-helmet";
import swal from 'sweetalert';
import { detailsOrder} from "../actions/orderActions";








function HomeOrder(props) {

  const [id_usuario, setId_usuario] = useState("");
  const [nombre, setNombre] = useState("");
  const [documento, setDocumento] = useState("");
  const [email, setEmail] = useState("");
  const [monto_compra,setMonto_compra] = useState("");
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const cart = useSelector((state) => state.cart);
  const orderCreate = useSelector((state) => state.orderCreate);
  const { success, order } = orderCreate;

  const { cartItems } = cart;

  const dispatch = useDispatch();

  const placeOrder = () => {
    swal("cargando", "...");
    dispatch(createOrder(id_usuario,nombre,documento,email,monto_compra,cart));
  
   
  };

  useEffect(() => {
    console.log("sucess",success)
    if (success) {
     
      dispatch({ type: ORDER_CREATE_RESET });
      
      swal({
        title: "Gracias por tu compra!",
        text: "Que tu Fútbol sea total",
        icon: "success",
        dangerMode: true,
      })
      .then(willDelete => {
        if (willDelete) {
         dispatch(detailsOrder(id_usuario));
         window.location.replace('/');
         
        }
      });
      
      
    }else {
      setId_usuario(userInfo.id_usuario)
      setNombre(userInfo.nombre);
      setDocumento(userInfo.documento);
      setEmail(userInfo.email);
      

      
    }
  },[success]);
  

  return (
    <div>
      <Helmet>
        <title>Comprar</title>



        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="%PUBLIC_URL%imagenes/logof.png" />
        <html lang="es" />
        <meta name="theme-color" content="#000000" />
        <meta name="robots" content="index, nofollow"/>
      </Helmet>
      <div className="cart">
        <div>
          <ul className="cart-items">
            {cartItems.length === 0 ? (
              <div>El carrito esta vacío</div>
            ) : (
              cartItems.map((item) => (
                <li key={item.id_producto}>
                  <div className="imagen-carrito">
                    <img
                      className="imagen-orden"
                      src={item.image}
                      alt={item.name}
                    ></img>
                  </div>
                  <div className="parrafo-carrito">
                    <p className="color-verde">Nivel {item.name_category}</p>
                    <p className="color-orange">{item.name}</p>
                  </div>

                  <div className="cart-price">
                    <p>{formatCurrency(item.price)} </p>
                  </div>
                </li>
              ))
            )}
          </ul>

           <div className="product-total-order">
            Total:{" "}
            {formatCurrency(
              cartItems.reduce((a, c) => a + c.price * c.count, 0)
            )}
            
          </div> 
        </div>
      </div>
      <hr />
     
      <div>
      
      <h2 className="centrar">DATOS DE FACTURA</h2>
      
        <div className="placeorder-action">
          <input className="input" type="hidden" placeholder={userInfo.id_usuario}/>
          <label className="username">Nombre o razón social</label>
          <input className="input" type="text" placeholder={userInfo.nombre} />
          <label className="username">RUC,CI O PASAPORTE:</label>
          <input
            className="input"
            type="text"
            placeholder={userInfo.documento}
          />
          <label className="username">Correo eléctronico</label>
          <input className="input" type="text" placeholder={userInfo.email} />
        </div>

       
      </div>
      <button className="button-red-promociones" onClick={placeOrder}>
        Continuar
      </button>
    </div>
  );
}

export default HomeOrder;
