
import React, { useState } from 'react';
import { Link} from 'react-router-dom';
import { logout } from '../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import "../Shopping.css";

function MiEspacio(props) {

    const dispatch = useDispatch();

    const userSignin = useSelector(state => state.userSignin);
    const { userInfo } = userSignin;

    let user 
    if(userInfo){
      user = userInfo.nombre ? userInfo.nombre.substring(0, 18)+"...":userInfo.nombre;
    }
  
   



    return (
      <div className="profile">
        {userInfo ? (
          <Link to="/mi-espacio">
           
              <button type="button" className="mi-espacio">
                <img
                  src="/imagenes/futbol.png"
                  className="icon-espacio"
                  alt="espacio educativo para desarrollo de habilidades fisicas y valores del futbolista"
                />
                <p className="saludo"> ¡Hola,{user}! </p>
              </button>
            
          </Link>
        ) : (
          <br/>
        )}
      </div>
    );
}

export default  MiEspacio;