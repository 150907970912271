import React, { Component } from "react";
import "../Shopping.css";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import Layout from "../Layout/Layout";
import { connect } from "react-redux";
import { fetchArticulos } from "../actions/articulosActions";
import Modal from "react-modal";
import Zoom from "react-reveal/Zoom";
import Rating from "../components/Rating";

import Saludo from "../MisVideos/Saludo";
import DatosT from "../components/DatosT";
import RatingScreenArt from "../screens/RatingScreenArt";
import { Helmet } from "react-helmet";

class HomeMiespacio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: null,
    };
  }
  componentWillMount() {
    Modal.setAppElement("body");
  }
  componentDidMount() {
    this.props.fetchArticulos();
  }
  openModal = (articulo) => {
    this.setState({ articulo });
  };
  closeModal = () => {
    this.setState({ articulo: null });
  };

  render() {
    const { articulo } = this.state;
    const { videos } = this.state;

    return (
      <Layout>
        <Helmet>
          <title>Tu espacio de crecimiento personal</title>

          <meta
            name="description"
            content="Un espacio donde podrás formarte de forma: BIOLÓGICA , SOCIOLÓGICA, EMOCIONAL, TÁCTICA Y TÉCNICA."
          />

          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="%PUBLIC_URL%imagenes/logof.png" />
          <html lang="es" />
          <meta name="theme-color" content="#000000" />
          <meta name="robots" content="index, follow"/>
        </Helmet>
        <h1 className="centrar">MI ESPACIO</h1>
        <main>
          <Fade bottom cascade={true}>
            <div className="container-fiscalizacion">
              <Link className="boton-fiscalizacion" to="/videos">
                <img
                  src="/imagenes/FT.T-91.png"
                  className="boton-fiscalizacion"
                  alt="Clases disponibles en todo momento y en todos los dispositivos."
                />
              </Link>

              <Link className="boton-fiscalizacion" to="/shopping">
                <img
                  src="/imagenes/shoppingcarrito-105.png"
                  className="boton-fiscalizacion-carrito"
                  alt="videos de enriquecimiento académico,fútbol para niños,fútbol para adultos y fútbol profesional"
                />
              </Link>
            </div>
          </Fade>
        </main>
        <hr />
        <h2 className="centrar color-red">ARTÍCULOS MÁS LEIDOS</h2>

        <Fade bottom cascade={true}>
          {!this.props.articulos ? (
            <div>Loading...</div>
          ) : (
            <div itemscope itemtype="https://schema.org/ScholarlyArticle">
              {this.props.articulos.map((articulo) => (
                <div key={articulo.id_articulo}>
                  <h2 itemprop="name" className="centrar">{articulo.title}</h2>
                  <div className="product-rating">
                    <Link
                      className="link-name"
                      // to={"#" + articulo.id_articulo}
                      to={`/article/${articulo.id_articulo}`}
                      onClick={() => this.openModal(articulo)}
                    >
                      {" "}
                      <Rating itemprop = "ratingValue"
                        className="centrar"
                        value={articulo.average_rating}
                        text={articulo.numreviews + " Reseñas"}
                      />
                    </Link>
                  </div>
                  <div className="imagen-rectangular">
                    <Link
                      className="link-name"
                      to={"#" + articulo.id_articulo}
                      onClick={() => this.openModal(articulo)}
                    >
                      <img
                        className="imagen-logo"
                        src={articulo.image2}
                        alt={articulo.title}
                      />
                    </Link>
                    <br />
                    <div className="texto-rendimiento2 centrado">
              
                    <p className=" nuestro-juego">
                      {articulo.summary_description}
                      </p>
                      <br /> <br />
                      <Link
                        to={`/article/${articulo.id_articulo}`}
                        onClick={() => this.openModal(articulo)}
                      >
                        <button className="button-red-forms">
                          {" "}
                          Continuar{" "}
                        </button>
                      </Link>
                      </div>
                  </div>
                  <hr />
                </div>
              ))}
            </div>
          )}
        </Fade>
      </Layout>
    );
  }
}
export default connect(
  (state) => ({
    articulos: state.articulos.items,
  }),
  {
    fetchArticulos,
  }
)(HomeMiespacio);
