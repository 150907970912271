import React, { Component } from "react";
import "../Shopping.css";
import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import Navigation from "../Layout/Navigation";
import Footer2 from "../Layout/Footer2";
import DateRange from "../components/DateRange";

class EntregaBeneficiarios extends Component {
  render() {
    return (
      <div>
        <Helmet>
        <title>Entrega a beneficiarios</title>

          <meta
            name="description"
            content="Aquí podrás observar el detalle de todos aquellos beneficiarios que han sido adjudicados con una beca de estudios, ayuda económica, artículos varios, etc."
          />

          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="%PUBLIC_URL%imagenes/logof.png" />
          <html lang="es" />
          <meta name="theme-color" content="#000000" />
          <meta name="robots" content="index, follow"/>
        </Helmet>
        <Navigation />
        <div>
          <h1 className="centrar">ENTREGA A BENEFICIARIOS </h1>
          <br />
          <h2 className="centrar">¿Encontraste a quién estas ayudando?</h2>
          <div>
            <DateRange />
          </div>
          <div className="search2">
            {" "}
            <i className="fa fa-search"></i>
            <input
              className="filter-fiscalizacion"
              placeholder=" Nombre de la persona o institución"
              value={this.props.category}
              onChange={(e) =>
                this.props.filterProducts(this.props.products, e.target.value)
              }
            />
          </div>
        </div>

        <div class="table">
          <table id="superTable" class="display" cellspacing="0" width="100%">
            <thead className="tabla-fondo">
              <tr>
                <th className="tabla">Nombre</th>
                <th className="tabla">Ayuda</th>
                <th className="tabla">Fecha</th>
              </tr>
            </thead>
          </table>
        </div>
        <main>
        <div class="floatingMenu">
          <ul class="menu vertical">
            <li>
              <a data-mode="true" data-column="0"></a>
            </li>
            <li>
              <a data-mode="true" data-column="1"></a>
            </li>
            <li>
              <a data-mode="true" data-column="2"></a>
            </li>
            <li>
              <a data-mode="true" data-column="3"></a>
            </li>
            <li>
              <a data-mode="true" data-column="4"></a>
            </li>
            <li>
              <a data-mode="true" data-column="5"></a>
            </li>
          </ul>
        </div>
        <br />
        <br />
        <button className="button-red-forms">Imprimir</button>
        <br />
        <br />
        <button className="button-red-forms">PDF</button>
        <br />
        <br />
        <button className="button-red-f">Hoja de cálculo</button>

        <Footer2 />
        </main>
      </div>
    );
  }
}

export default EntregaBeneficiarios;
