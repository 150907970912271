import React, {Component} from 'react';
import '../Shopping.css';


class Footer2 extends Component {
  render(){
  return (
<div className="site-footer">
<div className="footer-1"><b>Fundación Proinso.</b>
<br/>Desarrollado y donado por <b>QUATTRO S.A</b>
<br/>Apoya <b>TIPCSA</b>
</div>

            <img src="/imagenes/sello.png" className="sello" alt="El sello indica que este proyecto es propiedad de Fundación Proinso" />
            <img src="/imagenes/icc-109.png" className="sello2" alt="Whatsapp" />
 
</div>
  


);
}
}

 export default Footer2;