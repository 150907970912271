import React, { Component } from "react";
import "../Shopping.css";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import Layout from "../Layout/Layout";
import { connect } from "react-redux";
import { fetchProjects } from "../actions/ProjectActions";
import Modal from "react-modal";
import Zoom from "react-reveal/Zoom";
import { Helmet } from "react-helmet";




class Voluntariado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: null,
    };
  }
  componentWillMount() {
    Modal.setAppElement("body");
  }
  componentDidMount() {
    this.props.fetchProjects();
  }
  openModal = (project) => {
    this.setState({ project });
  };
  closeModal = () => {
    this.setState({ project: null });
  };

  render() {
    const { project } = this.state;
    const checkoutHandler = () => {
      this.props.history.push("/voluntariado-form");
    };

    return (
      <Layout>
        <Helmet>
        <title>Voluntariado</title>

          <meta
            name="description"
            content="Súmate a nuestro equipo, trabaja en algunos de nuestros proyectos y ayúdanos a llevar adelante el proceso de inclusion social"
          />

          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="%PUBLIC_URL%imagenes/logof.png" />
          <html lang="es" />
          <meta name="theme-color" content="#000000" />
          <meta name="robots" content="index, follow"/>
        </Helmet>
        <h1 className="centrar">VOLUNTARIADO</h1>
        <br />
        <main>
        <Fade bottom cascade={true}>
          {!this.props.projects ? (
            <div>Loading...</div>
          ) : (
            <div>
              {this.props.projects.volunteers.map((project) => (
                <div key={project.id_proyecto}>
                  <div className="contenedor-rendimiento ">
                    <div className="imagen-rectangular-dentro">
                      <h2 className="centrar">{project.name}</h2>
                      <Link
                        className="link-name"
                        onClick={() => this.openModal(project)}
                      >
                        <img
                          className="imagen-logo-dentro"
                          src={project.image}
                          alt={project.name}
                        />
                      </Link>
                      <br />

                      <div className="product-price">
                        ${project.summary_description}
                      </div>
                      <br />
                      <Link
                        to={`/voluntariado-form/${project.id_proyecto}`}
                        // to={"#" + project.id_proyecto}
                      >
                        <button
                          //onClick={`/padrinazgo-form/${project.id_project}`}
                          className="button-red-forms"
                        >
                          Súmate
                        </button>
                      </Link>
                      <br />
                      <br />
                      <Link onClick={() => this.openModal(project)}>
                        <button className="button-transparente-forms">
                          Conocer más
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}

              {project && (
                <Modal
                  className="my-modal"
                  isOpen={true}
                  onRequestClose={this.closeModal}
                >
                  <Zoom>
                    <button className="close-modal" onClick={this.closeModal}>
                      &times;
                    </button>
                    <hr />
                    <div>
                      <h1 className="centrar">VOLUNTARIADO</h1>
                      <h2 className="centrar">{project.name}</h2>
                      <img
                        className="imagen-logo-dentro"
                        src={project.image}
                        alt={project.name}
                      ></img>
                      <div>
                        <br />

                        <p className="nuestro-juego centrar">
                          ${project.descripcion}
                        </p>

                        <br />
                        <br />

                        <div className="product-price centrar">
                          <Link
                            to={`/voluntariado-form/${project.id_proyecto}`}
                            // to={"#" + project.id_proyecto}
                          >
                            <button
                              //onClick={`/padrinazgo-form/${project.id_project}`}
                              className="button-red-forms"
                            >
                              Súmate
                            </button>
                          </Link>
                          <br />
                          <br />
                          <button
                            className="button-transparente-forms"
                            onClick={() => {
                              this.closeModal();
                            }}
                          >
                            Volver
                          </button>
                        </div>
                      </div>
                    </div>
                  </Zoom>
                </Modal>
              )}
            </div>
          )}
        </Fade>
        </main>
        <div className="container-sello">
          <img src="/imagenes/sello.png" className="sello" alt="El sello indica que este proyecto es propiedad de Fundación Proinso" />
        </div>
      </Layout>
    );
  }
}
export default connect(
  (state) => ({
    projects: state.projects.items,
  }),
  {
    fetchProjects,
  }
)(Voluntariado);
