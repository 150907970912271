import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { registerVoluntariado,fetchProjects } from "../actions/ProjectActions";
import Layout from "../Layout/Layout";
import { Helmet } from "react-helmet";
import swal from 'sweetalert';

function Padrinazgoform(props) {
  const [id_proyecto, setId_proyecto] = useState("");
  const [nombre, setNombre] = useState("");
  const [fecha_nacimiento, setFecha_nacimiento] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [domicilio, setDomicilio] = useState("");
  const [projects,setProjects] = useState({});
  const userRegister = useSelector((state) => state.userRegister);
  const { loading, userInfo, error } = userRegister;
  const dispatch = useDispatch();
  const inputRef = useRef();

  // const redirect = props.location.search
  //   ? props.location.search.split("=")[1]
  //   : "/";
  // useEffect(() => {
  //   if (userInfo) {
  //     props.history.push(redirect);
  //   }
  //   return () => {
  //     //
  //   };
  // }, [userInfo]);
  useEffect(() => {
    async function projects() {
      const projects = await fetchProjects(props.match.params.id);
  
      setProjects(projects.data);
    }
    
  }, []);
  console.log(projects.data);
console.log(props.match.params.id)
  const submitHandler = (e) => {
	e.preventDefault();
  swal("Enviado!", "Muchas gracias por querer formar parte de FÚTBOL TOTAL,pronto nos contactaremos", "success");
    dispatch(
      registerVoluntariado(
        props.match.params.id,
        nombre,
        fecha_nacimiento,
        email,
        telefono,
        ciudad,
        domicilio
      )
    );
  };
  return (
    <Layout>
      <Helmet>
      <title>Datos para voluntariado</title>

        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="%PUBLIC_URL%imagenes/logof.png" />
        <html lang="es" />
        <meta name="theme-color" content="#000000" />
        <meta name="robots" content="index, nofollow"></meta>
      </Helmet>
      <section className="login">
        <h1 className="centrar">VOLUNTARIADO</h1>
        <form onSubmit={submitHandler} className="container-login-form">
          <div className="datos-forms">
            <h2>DATOS PERSONALES </h2>
            {loading && <div>Loading...</div>}
            {error && <div>{error}</div>}

            <input
              ref={inputRef}
              type="hidden"
              id="id_proyecto"
              name="id_proyecto"
              value={props.match.params.id}
              onChange={(e) => setId_proyecto(e.target.value)}
            />

            <input
              placeholder="Nombre y Apellido"
              className="input"
              type="nombre"
              name="nombre"
              id="nombre"
              onChange={(e) => setNombre(e.target.value)}
              required
            ></input>
            <input
              placeholder="fecha de nacimiento"
              className="input"
              type="fecha_nacimiento"
              name="fecha_nacimiento"
              id="fecha_nacimiento"
              onChange={(e) => setFecha_nacimiento(e.target.value)}
              required
            ></input>

            <input
              placeholder="Correo electrónico"
              className="input"
              type="email"
              name="email"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
              required
            ></input>

            <input
              placeholder="Celular o teléfono"
              className="input"
              type="telefono"
              id="telefono"
              name="telefono"
              onChange={(e) => setTelefono(e.target.value)}
              required
            ></input>

            <input
              className="input"
              placeholder="Ciudad"
              ref={inputRef}
              type="ciudad"
              id="ciudad"
              name="ciudad"
              //   value={userInfo.id_usuario}
              onChange={(e) => setCiudad(e.target.value)}
            />
            <input
              className="input"
              ref={inputRef}
              placeholder="Domicilio"
              type="domicilio"
              id="domicilio"
              name="domicilio"
              //   value={userInfo.id_usuario}
              onChange={(e) => setDomicilio(e.target.value)}
            />

            <button className="button-red-forms">Súmate</button>
            <br />
            <br />
          </div>
        </form>
      </section>
    </Layout>
  );
}
export default Padrinazgoform;
