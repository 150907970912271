import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { contacto } from "../actions/ProjectActions";
import Layout from "../Layout/Layout";
import swal from 'sweetalert';

function Padrinazgoform(props) {
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [mensaje, setMensaje] = useState("");

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, userInfo, error } = userRegister;
  const dispatch = useDispatch();
  const inputRef = useRef();

  const redirect = props.location.search
    ? props.location.search.split("=")[1]
    : "/";
  useEffect(() => {
    if (userInfo) {
      props.history.push(redirect);
    }
    return () => {
      //
    };
  }, [userInfo]);

  const submitHandler = (e) => {
    e.preventDefault();
    swal("Enviado!", "Gracias por contactarnos, Te responderemos lo antes posible", "success");
    dispatch(contacto(nombre, email, telefono, mensaje));
  };
  return (
    <Layout>
      <Helmet>
        <title>Contáctanos</title>

        <meta
          name="description"
          content="Ayuda con Fútbol total: Soluciones, información y más..."
        />

        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="%PUBLIC_URL%imagenes/logof.png" />
        <html lang="es" />
        <meta name="theme-color" content="#000000" />
        <meta name="robots" content="index, nofollow"></meta>
      </Helmet>
      <section className="login">
        <h1 className="centrar">CONTACTANOS</h1>
        <p className="centrar">
          Tu opinión es muy importante para nosotros.
          <br />
          Si tienes alguna duda o comentario por favor completa la información.
        </p>
        <form onSubmit={submitHandler} className="container-login-form">
          <main>
          <div className="datos-forms">
            {loading && <div>Loading...</div>}
            {error && <div>{error}</div>}
            <br />
            <br />
            <input
              placeholder="Nombre y Apellido"
              className="input"
              type="nombre"
              name="nombre"
              id="nombre"
              onChange={(e) => setNombre(e.target.value)}
              required
            ></input>

            <input
              placeholder="Correo electrónico"
              className="input"
              type="email"
              name="email"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
              required
            ></input>

            <input
              placeholder="Celular o teléfono"
              className="input"
              type="telefono"
              id="telefono"
              name="telefono"
              onChange={(e) => setTelefono(e.target.value)}
            ></input>
            <textarea
              className="comentarios"
              placeholder="Motivo"
              rows="8"
              cols="35"
              type="mensaje"
              id="mensaje"
              name="mensaje"
              onChange={(e) => setMensaje(e.target.value)}
              required
            ></textarea>

            <button className="button-red-forms">Enviar</button>
            <br />
            <br />
          </div>
          </main>
        </form>
      </section>
    </Layout>
  );
}
export default Padrinazgoform;
