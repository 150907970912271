import React from "react";
import "../Shopping.css";
import Layout from "../Layout/Layout";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

class Terminos extends React.Component {
  render() {
    return (
      <div>
        <Layout>
          <h1>Términos y condiciones</h1>
          <main>
            <Link to="/pdf/alimentacion.pdf" target="_blank" download>
              <b>
                <p>Políticas de privacidad-PDF</p>
              </b>
            </Link>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>

          </main>
          <br />
          <br />
        </Layout>
      </div>
    );
  }
}

export default Terminos;
