import React, { Component } from "react";
import { Link } from "react-router-dom";
import '../App.css'
import '../Shopping.css'



export class DatosT3 extends Component {

  render() {
      const courses_total= this.props.courses_total;
      const courses_taken =this.props.courses_taken;
      const porcentaje= courses_total > 0 ? (courses_taken / courses_total ) * 100 : 0;
      const porcentaje2= 100-porcentaje ;

    return (
      <div>
        <p className="datos-cursos">
          ¡Te falta solo
          <br /> un {porcentaje2}% para que tu fútbol sea total!
          <br />
         <Link className="link-name" to="/shopping">Ir al shopping</Link>
        </p>
      </div>
    );
  }
}

export default DatosT3;