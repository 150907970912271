import React from "react";
import "../Shopping.css";
import Layout from "../Layout/Layout";
import Filter from "../components/Filter";
import Products from "../screens/Products";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

class ReglasJuego extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
        <title>17 reglas del fútbol</title>

          <meta
            name="description"
            content="La FIFA es la federación internacional que rige el fútbol, por tanto es la encargada de proporcionar un reglamento para la celebración de los torneos, partidos….etc."
          />

          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="%PUBLIC_URL%imagenes/logof.png" />
          <html lang="es" />
          <meta name="theme-color" content="#000000" />
          <meta name="robots" content="index, follow"/>
        </Helmet>
        <Layout>
        <div itemscope itemtype="https://schema.org/ScholarlyArticle">
          <h1 className="centrar">MEJORÁ TU JUEGO</h1>
          <h2 itemprop="name"className="centrar">17 REGLAS DEL FÚTBOL</h2>
          <div className="reglas-j">
            <div className="video-contenidos">
              <ReactPlayer
                width="100%"
                height="100%"
                url="/videos/Habilidad-1.mp4"
                controls
              />
            </div>
            <main>
            <h2 className="centrar">PDF DESCARGABLE</h2>

            <div className="enlaces-pdf">
              <Link to="/pdf/regla1.pdf" target="_blank" download>
                <p>Reglas 1- El terreno de juego.</p>
              </Link>
              <Link to="/pdf/regla2.pdf" target="_blank" download>
                <p>Reglas 2- El balón.</p>
              </Link>
              <Link to="/pdf/regla3.pdf" target="_blank" download>
                <p>Reglas 3- El número de jugadores.</p>
              </Link>
              <Link to="/pdf/regla4.pdf" target="_blank" download>
                <p>Reglas 4- El equipamiento de los jugadores.</p>
              </Link>
              <Link to="/pdf/regla5.pdf" target="_blank" download>
                <p>Reglas 5- El árbitro</p>
              </Link>
              <Link to="/pdf/regla6.pdf" target="_blank" download>
                <p>Reglas 6- Los árbitros asistentes.</p>
              </Link>
              <Link to="/pdf/regla7.pdf" target="_blank" download>
                {" "}
                <p>Reglas 7- La duración del partido.</p>
              </Link>
              <Link to="/pdf/regla8.pdf" target="_blank" download>
                <p>Reglas 8- El inicio y la reanudación del juego.</p>
              </Link>
              <Link to="/pdf/regla9.pdf" target="_blank" download>
                <p>Reglas 9- El balón en juego o fuera de juego.</p>
              </Link>
              <Link to="/pdf/regla10.pdf" target="_blank" download>
                <p>Reglas 10- El gol marcado.</p>
              </Link>
              <Link to="/pdf/regla11.pdf" target="_blank" download>
                <p>Reglas 11- El fuera de juego.</p>
              </Link>
              <Link to="/pdf/regla12.pdf" target="_blank" download>
                <p>Reglas 12- Faltas e incorrecciones.</p>
              </Link>
              <Link to="/pdf/regla13.pdf" target="_blank" download>
                <p>Reglas 15- El saque de banda.</p>
              </Link>
              <Link to="/pdf/regla14.pdf" target="_blank" download>
                <p>Reglas 13- Tiros libres.</p>
              </Link>
              <Link to="/pdf/regla15.pdf" target="_blank" download>
                {" "}
                <p>Reglas 14- El tiro penal.</p>
              </Link>
              <Link to="/pdf/regla16.pdf" target="_blank" download>
                <p>Reglas 16- El saque de meta.</p>
              </Link>
              <Link to="/pdf/regla17.pdf" target="_blank" download>
                <p>Reglas 17- El saque de esquina.</p>
              </Link>
            </div>
            </main>
          </div>
          </div>
        </Layout>
      </div>
    );
  }
}

export default ReglasJuego;
