import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detailsUser, updateUserProfile } from "../actions/userActions";

import { USER_UPDATE_PROFILE_RESET } from "../constants/userConstants";
import Layout from "../Layout/Layout";
import { Helmet } from "react-helmet";

export default function PerfilScreen() {
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [contrasena, setContrasena] = useState("");
  const [nueva_contrasena, setNueva_contrasena] = useState("");
  const [documento, setDocumento] = useState("");

  const cart = useSelector((state) => state.cart);
  const { facturacion } = cart;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;
  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const {
    success: successUpdate,
    error: errorUpdate,
    loading: loadingUpdate,
  } = userUpdateProfile;
  const dispatch = useDispatch();
  useEffect(() => {
    if (!user) {
      dispatch({ type: USER_UPDATE_PROFILE_RESET });
      dispatch(detailsUser(userInfo.id_usuario));
    } else {
      setNombre(userInfo.nombre);
      setEmail(userInfo.email);
      setContrasena(userInfo.contrasena);
      setDocumento(userInfo.documento);
    }
  }, [dispatch, userInfo.id_usuario, user]);
  const submitHandler = (e) => {
    e.preventDefault();
    // dispatch update profile
    if (contrasena !== nueva_contrasena) {
      alert("Sus Contraseñas no coinciden");
    } else {
      dispatch(
        updateUserProfile({
          userId: userInfo.id_usuario,
          nombre,
          email,
          contrasena,
          documento,
        })
      );
      alert("Se realizaron los cambios con exito!");
    }
  };
  return (
    <Layout>
      <Helmet>
      <title>Perfil del usuario</title>

        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="%PUBLIC_URL%imagenes/logof.png" />
        <html lang="es" />
        <meta name="theme-color" content="#000000" />
        <meta name="robots" content="index, nofollow"></meta>
      </Helmet>
      <div>
        <h1 className="centrar">MIS DATOS</h1>
        <form onSubmit={submitHandler}>
          <div className="container-login-perfil-md">
            <h2>DATOS DE CUENTA</h2>
            <label className="username">Nombre de usuario</label>
            <input
              className="input"
              id="name"
              type="text"
              placeholder="Nombre de usuario"
              placeholder={userInfo.nombre}
              onChange={(e) => setNombre(e.target.value)}
            ></input>

            <label className="username">Correo eléctronico</label>
            <input
              className="input"
              id="email"
              type="email"
              placeholder={userInfo.email}
              onChange={(e) => setEmail(e.target.value)}
            ></input>

            <label className="username">Contraseña</label>
            <input
              className="input"
              id="password"
              type="password"
              placeholder="Contraseña"
              onChange={(e) => setContrasena(e.target.value)}
            ></input>

            <label className="username">Confirmar contraseña</label>
            <input
              className="input"
              id="nueva_contrasena"
              type="nueva_contrasena"
              placeholder="Confirmar contraseña"
              onChange={(e) => setNueva_contrasena(e.target.value)}
            ></input>
            <label className="username">RUC,CI O PASAPORTE</label>
            <input
              className="input"
              id="documento"
              type="documento"
              placeholder={userInfo.documento}
              onChange={(e) => setDocumento(e.target.value)}
            ></input>
          </div>

          <button className="button-red-forms" type="submit">
            Guardar cambios
          </button>
        </form>
      </div>
    </Layout>
  );
}
