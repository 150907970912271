import { ARTICULOS_LIST_FAIL, ARTICULOS_LIST_REQUEST, ARTICULO_DETAILS_FAIL, ARTICULO_DETAILS_REQUEST, ARTICULO_DETAILS_SUCCESS, ARTICULO_REVIEW_SAVE_FAIL, ARTICULO_REVIEW_SAVE_REQUEST, ARTICULO_REVIEW_SAVE_SUCCESS, FETCH_ARTICULOS } from "../Types";
import {URL_SERVER} from "../constants/userConstants";
import axios from 'axios';
import Axios from 'axios';

export const fetchArticulos = () => async (dispatch) => {
  dispatch({
    type: ARTICULOS_LIST_REQUEST,
  });

  try {
    const { data } = await Axios.get(URL_SERVER + "/listArticles");

    dispatch({ type: FETCH_ARTICULOS, payload: data.data });
  } catch (error) {
    dispatch({ type: ARTICULOS_LIST_FAIL, payload: error.message });
  }
};


// export const fetchArticulos = () => async (dispatch) => {
//     const res = await fetch(URL_SERVER+"/api/articulos");
//     const data = await res.json();
//     console.log(data);
//     dispatch({
//       type: FETCH_ARTICULOS,
//       payload: data,
//     });
//   };




  // export const detailsArticulo = (articuloId) => async (dispatch) => {
  //   try {
  //     dispatch({ type: ARTICULO_DETAILS_REQUEST, payload: articuloId });
  //     const { data } = await Axios.get(URL_SERVER+`/api/articulos/${articuloId}`);
  //     dispatch({ type: ARTICULO_DETAILS_SUCCESS, payload: data });
  //   } catch (error) {
  //     dispatch({ type: ARTICULO_DETAILS_FAIL, payload: error.message });
  //   }
  // };
  export const detailsArticulo2 = async (articuloId) => {
    //console.log("##debug")
    try {
  
      return await Axios.put(URL_SERVER+`/articles/${articuloId}`);
      //console.log(data);
   
    } catch (error) {
      
    }
    //console.log("##fin");
  };
  
  export const saveArticuloReview = (  
    comment,
    rating,
    id_articulo,
    id_usuario) => async (dispatch, getState) => {
    try {
       dispatch({ 
      type: ARTICULO_REVIEW_SAVE_REQUEST,
      payload: {
      comment: comment,
      rating: rating,
      id_articulo: id_articulo,
      id_usuario: id_usuario,
    },
   });
      const { data } = await axios.post(URL_SERVER+`/articles/reviews`,
      {
        comment: comment,
        rating: rating,
        id_articulo: id_articulo,
        id_usuario: id_usuario,
      });
      dispatch({ type: ARTICULO_REVIEW_SAVE_SUCCESS, payload: data });
    } catch (error) {
      //error
      dispatch({ type: ARTICULO_REVIEW_SAVE_FAIL, payload: error.message });
    }
  }
