import React, { useEffect } from "react";
import { addToCart, removeFromCart } from "../actions/CartActions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../Shopping.css";
import Layout from "../Layout/Layout";
import Cart from "../components/Cart";
import formatCurrency from "../components/Util";
import { Helmet } from "react-helmet";

function HomeCarrito(props) {
  const cart = useSelector((state) => state.cart);

  const { cartItems } = cart;

  const productId = props.match.params.id;
  const qty = props.location.search
    ? Number(props.location.search.split("=")[1])
    : 1;
  const dispatch = useDispatch();
  const removeFromCartHandler = (productId) => {
    dispatch(removeFromCart(productId));
  };
  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, qty));
    }
  }, []);

  const checkoutHandler = () => {
    props.history.push("/signin?redirect=payment");
  };

  return (
    <Layout>
            <Helmet>
            <title>Carrito de compra</title>

        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="%PUBLIC_URL%imagenes/logof.png" />
        <html lang="es" />
        <meta name="theme-color" content="#000000" />
        <meta name="robots" content="index, nofollow"></meta>
      </Helmet>
      <div className="container-shopping">
        <h1>CARRITO</h1>
        <main>
        <div className="homecarrito centrar">
        <Cart />
        </div>
        </main>

        <div className="product-details">
          <hr />
          <br/><br/>
          <h1>RESUMEN:</h1>
          <div className="product-price-details">
            Total: {formatCurrency(cartItems.reduce((a, c) => a + c.price * c.count, 0))}
          </div>
        </div>
        <hr />
        <div className="product-price">
          <br/><br/>
          <h1 className="centrado">PASOS PARA QUE TU FÚTBOL SEA TOTAL</h1>
          <ol className="ol">
            <li>
              <p>Presiona el botón comprar o seguí agregardo al carrito;</p>
            </li>
            <li>
              <p>
                Crear tu cuenta, o inicia sesión directamente si ya tienes una;
              </p>
            </li>
            <li>
              <p>Elije el método de pago de tu preferencia</p>
            </li>
            <li>
              <p>Disfruta del Fútbol Total</p>
            </li>
          </ol>
        </div>
      </div>
      <div className="product-details-actions2">
        <button
          onClick={checkoutHandler}
          className="button-red-forms"
          disabled={cartItems.length === 0}
        >
          Comprar ahora
        </button>
        <br />
        <Link to="/shopping">
          {" "}
          <button className="button-transparente-forms">
            Seguir en el shopping
          </button>
        </Link>
      </div>
    </Layout>
  );
}

export default HomeCarrito;
