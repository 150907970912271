import React, {Component} from'react';
import '../Shopping.css';
import { Link } from 'react-router-dom';
import Pasos from '../components/Pasos';

class Repetirc extends Component {
    render(){
    return (
		<section className='login'>
			      <Link to="/">
        {" "}
        <button className="close-modal">&times;</button>
      </Link>
      <hr />
      <br />

		<Link to="/"><img src='/imagenes/logof.png'className="App-logo" alt="logo" /></Link>
		<br/><br/><br/>
            
			<form className='container-login-form'>
			<h5>Olvidate tu contraseña</h5>
			<div className="datos-forms">
			<Pasos step1 step2 step3></Pasos>
			<input className='input'type='text'placeholder='Ingresa Contraseña nueva' />

			
			<input className='input'type='password'placeholder='Repetir contraseña' />

			<button className='button-red-forms'>Iniciar sesión </button>


			</div>
			</form>
			
		</section>
	
    );
}
}

export default Repetirc;