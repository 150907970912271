import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { recuperar } from "../actions/userActions";
import "../Shopping.css";
import { Helmet } from "react-helmet";
import swal from 'sweetalert';

function Contraseña(props) {
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();

  const redirect = props.location.search
    ? props.location.search.split("=")[1]
    : "/signin";
  const userRecuperar = useSelector((state) => state.userRecuperar);

  const { loading, userInfo, error } = userRecuperar;
  useEffect(() => {
    if (userInfo) {
      props.history.push(redirect);
    }
    return () => {};
  }, [userInfo]);

  const submitHandler = (e) => {
    swal("Revisa tu correo elécctronico!", "Te enviamos un código", "success");
    e.preventDefault();
    dispatch(recuperar(email));
  };

  return (
    <section className="login">
      <Helmet>
        <title>Recupera tu cuenta</title>

        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="%PUBLIC_URL%imagenes/logof.png" />
        <html lang="es" />
        <meta name="theme-color" content="#000000" />
        <meta name="robots" content="noindex, nofollow"></meta>
      </Helmet>

      <Link to="/">
        <button className="close-modal">&times;</button>
      </Link>
      <hr />

      <br />

      <Link to="/">
        <img src="/imagenes/logof.png" className="App-logo" alt="logo" />
      </Link>

      <br />
      <br />
      <br />

      <form onSubmit={submitHandler} className="container-login-form">
        <h5>Olvidate tu contraseña</h5>
        <div className="datos-forms">
          <p>Enviaremos una nueva contraseña a tu correo eléctronico</p>

          <input
            className="input"
            type="text"
            placeholder="E-mail"
            onChange={(e) => setEmail(e.target.value)}
          />

          <button className="button-red-forms">Enviar código </button>
        </div>
      </form>
    </section>
  );
}
export default Contraseña;
