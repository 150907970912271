import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detailsOrder, updateUserProfile } from "../actions/orderActions";
import formatCurrency from "../components/Util";
import { USER_UPDATE_PROFILE_RESET } from "../constants/userConstants";
import Layout from "../Layout/Layout";
import { Helmet } from "react-helmet";
import Fade from "react-reveal/Fade";
import Axios from "axios";
import { URL_SERVER} from '../constants/userConstants';

export default function OrderScreen(props) {

  const [contrasena, setContrasena] = useState("");
  const [nueva_contrasena, setNueva_contrasena] = useState("");
  const [orders,setOrders] = useState("")

  const cart = useSelector((state) => state.cart);
  const { facturacion } = cart;
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const orderDetails = useSelector((state) => state.orderDetails);
  const { loading, error, user } = orderDetails;
  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const {
    success: successUpdate,
    error: errorUpdate,
    loading: loadingUpdate,
  } = userUpdateProfile;
 console.log(userInfo)
 
 let id_usuario = 0
  if (userInfo == null) {
    ////window.location.replace('/');
  } else {
     id_usuario = userInfo.id_usuario === null ?  0 : userInfo.id_usuario
  }
  const dispatch = useDispatch();


  useEffect(() => {
    async function orders(id_usuario) {
      const { data } = await Axios.put(
        URL_SERVER + `/ordersUser/${id_usuario}`,
        { id_usuario: id_usuario }
      );
      setOrders(data.data);
      dispatch(detailsOrder(id_usuario));
    }
    
    if (!user) {

     if(localStorage.facturacion!== undefined){
      if (localStorage.facturacion != "" ) {
        const arrayOrder = JSON.parse(localStorage.facturacion)

        setOrders(arrayOrder); 
        dispatch(detailsOrder(id_usuario));
      } else {
        orders(id_usuario);
      }
       
     }else {
      orders(id_usuario);
    }
     
    } 
  }, [dispatch, id_usuario, user,localStorage.facturacion]);


  console.log("hola12",orders)
  return (
    <div>
    <Layout>
      <Helmet>
        <title>Perfil del usuario</title>

        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="%PUBLIC_URL%imagenes/logof.png" />
        <html lang="es" />
        <meta name="theme-color" content="#000000" />
        <meta name="robots" content="index, nofollow"></meta>
      </Helmet>
      <div>
      
        <h1 className="centrar">MIS COMPRAS</h1>

        <div >
          {orders == "" ? (
            <div className="compras"><h2 className="centrar">No tienes pedidos</h2></div>
          ) : (
            <div  >
             
              {orders.map((order) => (
                <Fade left cascade>
                <div key={order.orden_id}>
                   <div className="container-login-form-orden">
                  <ul className="tabla-orden">
                  <li className="grilla-orden-ico">
                  <img
                  src="/imagenes/compras-116.png"
                  className="redes-s-salir"
                  alt="Perfil del usuario"
                />
                   
                  </li>
                  <li className="grilla-orden">
                  <p className="color-orange">Fecha de compra</p>
                   <p> {order.fecha}</p>
                  </li>
                  <li className="grilla-orden" >
                  <p className="color-orange">Orden de compra</p>
                   <p>{order.orden_id}</p> 
                  </li>
                  <li className="grilla-orden" >
                  <p className="color-orange">Monto de compra</p>
                    <p>{formatCurrency(order.monto_compra)}</p>
                    
                  </li>
                  </ul>
                </div>
                </div>
                </Fade>
              ))}
              
            </div>
          )}
        </div>
      </div>
    </Layout>
    </div>
  );
}

