import React, { Component } from 'react';
import '../Shopping.css';
import Navigation from '../Layout/Navigation';
import Footer2 from '../Layout/Footer2';
import DateRange from '../components/DateRange';
import { Helmet } from "react-helmet";






class IngresosEgresos extends Component {

    render(){
    return (
      <div>
        <Helmet>
          <title>Ingresos y egresos</title>

          <meta
            name="description"
            content="Aquí podrás observar todos los ingresos y egresos de la plataforma de Fútbol total y de los distintos proyectos."
          />

          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="%PUBLIC_URL%imagenes/logof.png" />
          <html lang="es" />
          <meta name="theme-color" content="#000000" />
          <meta name="robots" content="index, follow"/>
        </Helmet>
        <Navigation />

        <h1 className="centrar">INGRESOS Y EGRESOS </h1>
        <br />
        <h2 className="centrar">
          Podes ver ingreso y egreso del que fromas parte
        </h2>
        <div>
          <DateRange />
        </div>

        <div>
          {" "}
          <select
            className="filter-fiscalizacion"
            value={this.props.category}
            onChange={(e) =>
              this.props.filterProducts(this.props.products, e.target.value)
            }
          >
            <option value="">Seleccione Proyecto</option>
            <option value="inicial">inicial</option>
            <option value="intermedio">intermedio</option>
            <option value="avanzado">avanzado</option>
            <option value="profesional">profesional</option>
          </select>
        </div>

        <div className="container-check">
          <p>¿Qué ingresos quisieras ver?</p>
          <label className="username">
            <input type="checkbox" />
            Donaciones
          </label>
          <br />
          <label className="username">
            <input type="checkbox" />
            Padrinazgo
          </label>
          <br />
          <label className="username">
            <input type="checkbox" />
            Todos
          </label>
          <br />
        </div>
        <div className="container-check">
          <p>¿Qué egresos quisieras ver?</p>
          <label className="username">
            <input type="checkbox" />
            Donaciones
          </label>
          <br />
          <label className="username">
            <input type="checkbox" />
            Padrinazgo
          </label>
          <br />
          <label className="username">
            <input type="checkbox" />
            Todos
          </label>
          <br />
        </div>
        <div class="table">
          <table id="superTable" class="display" cellspacing="0" width="100%">
            <thead className="tabla-fondo">
              <tr>
                <th className="tabla">Proyecto</th>
                <th className="tabla">Medios de pagos</th>
                <th className="tabla">total</th>
              </tr>
            </thead>
          </table>
        </div>
        <main>
        <div class="floatingMenu">
          <ul class="menu vertical">
            <li>
              <a data-mode="true" data-column="0"></a>
            </li>
            <li>
              <a data-mode="true" data-column="1"></a>
            </li>
            <li>
              <a data-mode="true" data-column="2"></a>
            </li>
            <li>
              <a data-mode="true" data-column="3"></a>
            </li>
            <li>
              <a data-mode="true" data-column="4"></a>
            </li>
            <li>
              <a data-mode="true" data-column="5"></a>
            </li>
          </ul>
        </div>
        </main>
        <br />
        <br />
        <button className="button-red-forms">Ver</button>

        <Footer2 />
      </div>
    );
  }
  }
  
  export default IngresosEgresos;