import React, { Component } from "react";
import "../Shopping.css";
import Fade from "react-reveal/Fade";
import Modal from "react-modal";
import { connect } from "react-redux";
import { fetchProducts } from "../actions/ProductActions";
import { addToCart } from "../actions/CartActions";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import formatCurrency from "./Util";

class Promociones extends Component {
  componentWillMount() {
    Modal.setAppElement("body");
  }
  constructor(props) {
    super(props);
    // console.log("promotions",props)
    this.state = {
      product: null,
      showCheckout: false,
    };
  }

  componentDidMount() {
    this.props.fetchProducts();
  }

  openModal = (product) => {
    this.setState({ product });
  };
  closeModal = () => {
    this.setState({ product: null });
  };
  render() {
    const checkoutHandler = () => {
      this.props.history.push("/signin?redirect=payment");
    };

    const { product } = this.state;
    let slider = 0;
    if (!this.props.products) {
      slider = 0;
    } else {
      slider = this.props.products.promotions.length;
      if (slider > 4) {
        slider = 4;
      }
    }

    // const slider = this.props.products.promotions.length+1
    console.log("slider", slider);
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: slider,
      slidesToScroll: 1,
      cssEase: "linear",
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: slider,
            slidesToScroll: 2,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow:slider,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow:slider,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div>
        <Fade bottom cascade>
          {!this.props.products ? (
            <div>Loading...</div>
          ) : (
            <Slider {...settings}>
       
              {this.props.products.promotions.map((product) => (
                <div key={product.id_producto}>
                  <div itemscope itemtype = "https://schema.org/IndividualProduct" itemid = {product.id_producto} >
                  <div className="carrito">
                    <div className="product">
                      <div itemprop = "name"  className="product-name">
                        <p>{product.name}</p>
                      </div>
                      <Link
                        className="link-name"
                        to={`/products/${product.id_producto}`}
                        onClick={() => this.openModal(product)}
                      >
                        <img itemprop = "image"
                          className="product-image"
                          src={product.image}
                          alt={product.name}
                        />
                      </Link>
                      <br />
                      <div itemprop = "description" className="product-brand">{product.brand}</div>
                    </div>
                    <Link
                      className="link-name"
                      to={`/products/${product.id_producto}`}
                      onClick={() => this.openModal(product)}
                    >
                      Ver más...{" "}
                    </Link>

                    <div className="product-price">{formatCurrency(product.price)}</div>

                    <br />

                    <Link to="/signin?redirect=payment">
                      <button
                        onClick={() => this.props.addToCart(product)}
                        className="button-red-promociones"
                      >
                        Comprar
                      </button>
                    </Link>

                    {/* <Modal1 /> */}
                    <br />
                    <br />
                    <button
                      onClick={() => this.props.addToCart(product)}
                      className="button-transparente-promociones"
                    >
                      Agregar al carrito
                    </button>
                    <br />
                    <br />
                  </div>
                </div>
                </div>
              ))}
            </Slider>
          )}
        </Fade>
        
      </div>
    );
  }
}
export default connect(
  (state) => ({ products: state.products.filteredItems }),
  {
    fetchProducts,
    addToCart,
  }
)(Promociones);
