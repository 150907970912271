export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST';
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS';
export const USER_SIGNIN_FAIL = 'USER_SIGNIN_FAIL';

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST';
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS';
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL';
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

export const USER_RECUPERAR_REQUEST = 'USER_RECUPERAR_REQUEST';
export const USER_RECUPERAR_SUCCESS = 'USER_RECUPERAR_SUCCESS';
export const USER_RECUPERAR_FAIL = 'USER_RECUPERAR_FAIL';


export const USER_FACEBOOK_REQUEST = 'USER_FACEBOOK_REQUEST';
export const USER_FACEBOOK_SUCCESS = 'USER_FACEBOOK_SUCCESS';
export const USER_FACEBOOK_FAIL = 'USER_FACEBOOK_FAIL';


export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL';
export const USER_FACEBOOK_LOGOUT = 'USER_FACEBOOK_LOGOUT';

export const USER_LOGOUT = 'USER_LOGOUT';

export const URL_SERVER = " https://quattropy.net/futboltotal/server/public/api";