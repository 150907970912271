import React, { useState, useEffect } from 'react';
import { Link,useHistory } from 'react-router-dom';
import { logout, update } from '../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import "../Shopping.css";

function MisDatos(props) {
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const dispatch = useDispatch();

    const userSignin = useSelector(state => state.userSignin);
    const { userInfo } = userSignin;
    const handleLogout = () => {
        dispatch(logout());
        
    }
    




    return (
      <div >
        {userInfo ? (
            <div>
              <hr/>
          

           <div className="lista-sidebar">
           <Link to="/perfil">
              
              
              <img
                  src="/imagenes/datos-110.png"
                  className="redes-s-salir"
                  alt="Perfil del usuario"
                />
                <p className="saludo"> Mis datos</p>
                
              
              </Link>

              </div>
              

              <br/>
          
          </div>
          
        ) : (
         <br/>
        )}
        
      </div>
    );
}

export default  MisDatos;