import React, { Component } from "react";
import "../Shopping.css";
import { Link } from "react-router-dom";
import Layout from "../Layout/Layout";
import { Helmet } from "react-helmet";
class HomeApoyanos extends Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Apoyá los proyectos</title>

          <meta
            name="description"
            content="Somos Fútbol total y necesitamos tu ayuda para seguir cambiando vidas. Ayuda donando, apadrinando, con tu tiempo, conocimiento o lo que puedas aportar."
          />

          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="%PUBLIC_URL%imagenes/logof.png" />
          <html lang="es" />
          <meta name="theme-color" content="#000000" />
          <meta name="robots" content="index, follow"/>
        </Helmet>
        <main>
          <h1 className="centrar">¡APOYÁ LOS PROYECTOS!</h1>
          <h2 className="centrar">QUIERO DONAR</h2>
          <div className="imagen-rectangular">
            <img
              src="/imagenes/donador-01.png"
              className="imagen-logo"
              alt=" Proyectos que implementamos como una organizacion sin fines de lucro que busca la igualdad de oportunidades,promover el bienestar de niñas, niños y jóvenes."
            />

            <br />
            <div className="texto-rendimiento2 centrado ">
              <p className="nuestro-juego">
                Es la persona que decide dar un aporte cada vez que puede; para
                que un proyecto o plan de Fútbol Total, sea llevado adelante con
                el objetivo de lograr la inclusión social por medio del fútbol.
              </p>
              <br />
              <br />
              <Link to="/donaciones">
                <button className="button-red-forms">Doná</button>
              </Link>
            </div>
          </div>
          <hr />
          <h2 className="centrar">QUIERO APADRINAR</h2>
          <div className="imagen-rectangular">
            <img
              src="/imagenes/padrinazgo-05.png"
              className="imagen-logo"
              alt="organizacion no gubernamental que busca la integración y la cohesión comunitaria a través del juego y el deporte."

            />

            <br />

            <div className="texto-rendimiento2 centrado">
              <p className="nuestro-juego">
                Es la persona que siente importante contribuir a la inclusión
                social de niños y niñas por medio del Fútbol, eligiendo el
                importe mensual deseado; para que los proyectos sociales de
                Fútbol Total, puedan ser sostenibles durante el tiempo y que la
                niñez pueda ser una etapa feliz.
              </p>
              <br />
              <br />

              <Link to="/padrinazgo">
                <button className="button-red-forms">Apadriná</button>
              </Link>
            </div>
          </div>
          <hr />
          <h2 className="centrar">QUIERO SER VOLUNTARIO</h2>
          <div className="imagen-rectangular">
            <img
              src="/imagenes/voluntariado-03.png"
              className="imagen-logo"
              alt="Vení a construir, buscamos personas que quieran ser protagonistas de transformación"
            />

            <div className="texto-rendimiento2 centrado">
              <p className=" nuestro-juego">
                Es la persona que decide aportar su tiempo, conocimiento,
                trabajo y compromiso; para que Fútbol Total, pueda estar al
                servicio de la inclusión social por medio del fútbol.
              </p>
              <br />
              <br />
              <Link to="/voluntariado">
                <button className="button-red-forms">Súmate</button>
              </Link>
            </div>
          </div>
          <hr />
          <h2 className="centrar">FISCALIZA TU APORTE</h2>
          <div className="imagen-rectangular">
            <img
              src="/imagenes/FISCALIZA-04.png"
              className="imagen-logo"
              alt="Herramienta de libre acceso que te permite administrar,controlar y participar de forma transparente de todos los ingresos y egresos del Proyecto"
            />

            <div className="texto-rendimiento2 centrado">
              <p className=" nuestro-juego">
                Te proponemos esta herramienta donde podrás controlar los
                recursos disponibles de nuestros proyectos que provienen de las
                actividades económicas y otras fuentes como donaciones y
                padrinazgo. ¡Sé protagonista!
              </p>
              <br />
              <br />
              <Link to="/fiscalizacion">
                <button className="button-red-forms">Fiscaliza</button>
              </Link>
            </div>
          </div>
        </main>
      </Layout>
    );
  }
}
export default HomeApoyanos;
