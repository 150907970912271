
import React from "react";
import { Link} from "react-router-dom";
import { logout } from "../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import "../Shopping.css";

function ProfileScreen(props) {

  const dispatch = useDispatch();

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const handleLogout = () => {
    dispatch(logout());
  };
console.log(userInfo)
  return (
    <div>
      {userInfo ? (
        <div className="lista-sidebar-700-iz">
        <Link to="/">
          <button type="button" onClick={handleLogout} className="sesion">
            <p>Cerrar sesión</p><br/>
          </button>
        </Link>
        </div>
      ) : (
        <div className="lista-sidebar-700-iz">
        <Link to="/signin">
          <p>Iniciar sesión</p>
        </Link>
        
        </div>
      )}
    </div>
  );
}

export default ProfileScreen;