import React, { Component } from "react";
import "../Shopping.css";
import Layout from "../Layout/Layout";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

class HomeMejora extends Component {
  render() {
    return (
      <Layout>
        <Helmet>
        <title>Llevá tu rendimiento al siguiente nivel</title>

          <meta
            name="description"
            content="Encontrarás herramientas para mejorar tu conocimiento sobre fútbol, salud nutricional y autoestima, a través de la mejora continua y el esfuerzo personal"
          />

          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="%PUBLIC_URL%imagenes/logof.png" />
          <html lang="es" />
          <meta name="theme-color" content="#000000" />
          <meta name="robots" content="index, follow"/>
        </Helmet>
        <div>
          <main>
          <h1>MEJORÁ TU RENDIMIENTO DEPORTIVO</h1>
          <h2 className="centrar">17 REGLAS DEL FÚTBOL</h2>
          <div className="imagen-rectangular">
            <img
              src="/imagenes/fifa.png"
              className="imagen-logo"
              alt="REGLAS-FUTBOL-FIFA, Explotá todo tu potencial con una correcta interpretación del juego y adquirí todos los conocimientos para jugar cada vez mejor."
            />

            <br />
            <div className="texto-rendimiento2 centrado">
              <p className=" nuestro-juego">
                Todos creemos conocer pero, ¿Realmente sabemos las reglas del
                juego? Si dudas,aquí te contamos más...
              </p>

              <Link to="/reglas-del-juego">
                <button className="button-red-forms">Continuar</button>
              </Link>
            </div>
          </div>
          <hr />
          <h2 className="centrar">ALIMENTACIÓN INTELIGENTE</h2>
          <div className="imagen-rectangular">
            <img
              src="/imagenes/vegetales.png"
              className="imagen-logo"
              alt="ALIMENTACIÓN INTELIGENTE, Explotá todo tu potencial con una alimentación consciente"
            />

            <br />

            <div className="texto-rendimiento2 centrado">
              <p className=" nuestro-juego">
                La nutrición ha sido motivo de interés por parte del cuerpo
                técnico de equipos profesionales; quienes cada vez más,son
                conscientes de los beneficios para sus atletas. Debido que
                favorece al aporte energético y brinda los nutrientes necesarios
                para garantizar el rendimiento.
              </p>
              <Link to="/alimentacion">
                <button className="button-red-forms">Continuar</button>
              </Link>
            </div>
          </div>
          </main>
          <hr />
          <h2 className="centrar">NUESTRO SHOPPING ONLINE</h2>
          <div className="imagen-rectangular">
            <img
              src="/imagenes/compra-shopping.png"
              className="imagen-logo"
              alt="shopping de Fútbol"
            />

            <div className="texto-rendimiento2 centrado">
              <p className=" nuestro-juego">
                ¡Ingresá a nuestro shopping de técnicas del juego y que tu
                fútbol sea total!
              </p>
              <br />
              <Link to="/shopping">
                <button className="button-red-forms">IR AL SHOPPING</button>
              </Link>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
export default HomeMejora;
