import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { savePayment } from "../actions/CartActions";
import Layout from "../Layout/Layout";
import Cart from "../components/Cart";
import HomeOrder from "../screens/HomeOrder";
import { Helmet } from "react-helmet";


function Payment(props) {
  return (
    <Layout>
      <Helmet>
        <title>REVISÁ Y CONFIRMA TU COMPRA</title>

        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="%PUBLIC_URL%imagenes/logof.png" />
        <html lang="es" />
        <meta name="theme-color" content="#000000" />
        <meta name="robots" content="index, nofollow"></meta>
      </Helmet>
      <main>
      <div>
        <h1>REVISÁ Y CONFIRMA TU COMPRA</h1>
        <h2 className="centrar">DETALLE DE COMPRA</h2>
        <HomeOrder />
      </div>
      </main>
    </Layout>
  );
}
export default Payment;
