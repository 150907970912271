import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  detailsArticulo2,
  saveArticuloReview,
} from "../actions/articulosActions";
import Rating from "../components/Rating";
import { ARTICULO_REVIEW_SAVE_RESET } from "../Types";
import Layout from "../Layout/Layout";
import { Helmet } from "react-helmet";
import swal from 'sweetalert';



function RatingScreenArt(props) {
  const [articulo, setArticulos] = useState({});
  const [comments, setComments] = useState({});
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [id_articulo,setId_articulo] = useState("");
  const [id_usuario,setId_usuario] = useState("");
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  let articuloDetails2 = useSelector((state) => state.productDetails);

  const articuloReviewSave = useSelector((state) => state.articuloReviewSave);
  const { success: articuloSaveSuccess } = articuloReviewSave;
  const dispatch = useDispatch();
  const inputRef = useRef();

  useEffect(() => {
    


    async function articulos() {
      const articulos = await detailsArticulo2(props.match.params.id);

      setArticulos(articulos.data.data.articles[0]);

    }
    
    async function comments() {
      const comments = await detailsArticulo2(props.match.params.id);

      setComments(comments.data);

    }


    if (articuloSaveSuccess) {
      swal("Enviado!", "Gracias por tu reseña", "success");
      setComment("");
      setRating(0);
      setId_articulo(0);
      setId_usuario("");
      dispatch({ type: ARTICULO_REVIEW_SAVE_RESET });
    } else {
      articulos();
      comments();
    }
  }, [articuloSaveSuccess, inputRef]);

  const submitHandler = (e) => {
    e.preventDefault();
    // dispatch actions
    dispatch(
      saveArticuloReview(
        comment,
        rating,
        props.match.params.id,
        userInfo.id_usuario,
      )
    );
  };
    

  return (
    <div>
      <Layout>
      <Helmet>
          <title>{articulo.title}</title>
          <meta name="description" content={articulo.subtitle} />
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="%PUBLIC_URL%imagenes/logof.png" />
          <html lang="es" />
          <meta name="theme-color" content="#000000" />
          <meta name="robots" content="index, follow"></meta>
        </Helmet>
        <div itemscope itemtype="https://schema.org/ScholarlyArticle">
      {articulo && (

                    <main>
                      <h1 itemprop="name">{articulo.title}</h1>
                      
                      <div className="product-rating">
                      <h2>{articulo.subtitle}</h2>
                      
                        <Link
                          className="link-name"
                          // to={"#" + articulo.id_articulo}
                          to={`/articulo/${articulo.id_articulo}`}
                          onClick={() => this.openModal(articulo)}
                        >
                          {" "}
                          <Rating itemprop = "ratingValue" clasName="centrar"
                            value={articulo.average_rating}
                            text={articulo.numreviews + " Reseñas"}
                          />
                        </Link>
                      </div>
                      <br/><br/>
                      
                      <img
                        className="imagen-logo-articulos"
                        src={articulo.image1}
                        alt={articulo.title}
                      ></img>
                      <div className="product-details-description">
                        <br />
                        <br />
                        <p itemprop="description" className="centrar nuestro-juego">
                          {articulo.description}
                        </p>
                      </div>
                      <br />
                      <br />

                      <img
                        className="imagen-logo-articulos"
                        src={articulo.image2}
                        alt={articulo.title}
                      ></img>
                      <br />
                      <br />
                      <div className="product-details-description">


                        <div className="product-price centrar">


                        </div>
                        
                      </div>
                    </main>


              )}

        <h2 className="centrar">
          Tu comentario es muy importante para nosotros
        </h2>
        {userInfo ? (
          <form onSubmit={submitHandler}>
            <div className="datos-forms">
              <select
               className="filter-comentarios"
                ref={inputRef}
                name="rating"
                id="rating"
                value={rating}
                onChange={(e) => setRating(e.target.value)}
              >
                <option value="1">1- Malo</option>
                <option value="2">2- Regular</option>
                <option value="3">3- Bueno</option>
                <option value="4">4- Muy bueno</option>
                <option value="5">5- Excelente</option>
              </select>

              <textarea
                ref={inputRef}
                className="comentarios"
                placeholder="Comentarios"
                rows="8"
                cols="35"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>

              <button type="submit" className="button-red-forms">
                Enviar
              </button>
            </div>
          </form>
        ) : (
          <div>
            <Link className="login-olvidaste" to="/signin">
              Inicia sesión para dejarnos tu comentario.
            </Link>
          </div>
        )}
       <br />
        <br />
        <h2 className="centrar">Comentarios</h2>
        <br />

{Object.keys(comments).length == 0 ? (
  <div className="centrar">
    <p>No hay comentarios</p>
  </div>
) : (
  comments.data.comments.map((review) => (
    <div className="reviews-name">
      <div key={review._id}>
        <div>
          <p>{review.name}</p>
        </div>
        <div>
          <Rating itemprop = "ratingValue" value={review.rating}></Rating>
        </div>
        <div>
          <p itemprop = "aggregateRating">{review.comment}</p>
        </div>
      </div>
    </div>
  ))
)}
</div>
      </Layout>
    </div>
  );
}
export default RatingScreenArt;
