import React from "react";
import "../Shopping.css";
import Layout from "../Layout/Layout";
import Filter from "../components/Filter";
import Products from "../screens/Products";
import Promociones from "../components/Promociones";
import { Helmet } from "react-helmet";

class HomeScreen extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Shopping virtual</title>
          <meta
            name="description"
            content="Dirigido a Clubes, Asociaciones Deportivas, Escuelas de Fútbol, niños/as, y a todo aquel con ganas de mejorar su dominio de la pelota o divertirse"
          />
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="%PUBLIC_URL%imagenes/logof.png" />
          <html lang="es" />
          <meta name="theme-color" content="#000000" />
          <meta name="robots" content="index, follow"></meta>
        </Helmet>
        <div className="container-shopping">
          <h1 className="centrar">SHOPPING ONLINE</h1>
          <Filter></Filter>
          <main>
            <div className="promociones">
              <br />
              <h2 className="color-white"> PROMOCIONES</h2>

              <Promociones />
              <br />
              <br />
            </div>
            <br />
            <h2 className="centrar color-red">
              MEJORÁ TU DOMINIO DE LA PELOTA
            </h2>
            <br />
            <Products></Products>

            <br />
            <br />
          </main>
        </div>
      </Layout>
    );
  }
}

export default HomeScreen;
