import React, { Component } from "react";
import "../Shopping.css";
import Layout from "../Layout/Layout";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

class Faqs extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Preguntas Frecuentes</title>

          <meta name="description" content="Preguntas Frecuentes" />

          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="%PUBLIC_URL%imagenes/logof.png" />
          <html lang="es" />
          <meta name="theme-color" content="#000000" />
        </Helmet>
        <Layout>
          <h1 className="centrar">Preguntas frecuentes</h1>
          <main className="faqs">
            <div
              itemscope
              itemprop="mainEntity"
              itemtype="https://schema.org/Question"
            >
              <h2 className="centrar" itemprop="name">
                ¿QUÉ ES FÚTBOL TOTAL?
              </h2>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    Fútbol Total es un proyecto impulsado por la{" "}
                    <a href="http://www.fundacionproinso.org/">
                      {" "}
                      Fundación Proinso
                    </a>
                    , inspirado en el Método Coerver (Wiel Coerver), en la
                    periodización táctica (Victor Frade) y en el entrenamiento
                    estructurado de Paco Seirulo, apoyado en la Teoría de las
                    Emociones del Dr. Antonio Damasio, proponiendo actividades y
                    herramientas apoyadas en la tecnología, que buscar incidir
                    sobre aspecto técnicos, tácticos, físicos y en la formación
                    integral del jugador/a en relación a los valores a través
                    del deporte.
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div
              itemscope
              itemprop="mainEntity"
              itemtype="https://schema.org/Question"
            >
              <h2 className="centrar" itemprop="name">
                ¿QUÉ HACEMOS?
              </h2>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    Nos hemos dedicado a acumular conocimiento sobre el fútbol
                    en sus niveles iniciales, para capacitar a equipos humanos
                    que repliquen metodologías en el FÚTBOL BASE. Este
                    intercambio de conocimiento y experiencias enriquece a todos
                    y permite el crecimiento personal de todos los individuos
                    relacionados con el deporte.
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div
              itemscope
              itemprop="mainEntity"
              itemtype="https://schema.org/Question"
            >
              <h2 className="centrar" itemprop="name">
                ¿CÓMO LO HACEMOS?
              </h2>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    Con la propuesta de un sistema de trabajo integrado (STI),
                    utilizando el inmenso poder del fútbol como herramienta para
                    lograr nuestros objetivos. -Visión a largo plazo.
                    <br /> -Objetivos realizables con acciones y esfuerzos
                    permanentes.
                    <br /> -Orden e institucionalidad como marco de la evolución
                    continua.
                    <br /> -Capacitación circular de todos los involucrados.
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div
              itemscope
              itemprop="mainEntity"
              itemtype="https://schema.org/Question"
            >
              <h2 className="centrar" itemprop="name">
                ¿QUÉ ES EL SHOPPING ONLINE DE FÚTBOL TOTAL?{" "}
              </h2>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    Es una tienda virtual sin fines de lucro que busca brindar
                    soluciones deportivas, siendo una herramienta de desarrollo
                    individual, social y económico.
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div
              itemscope
              itemprop="mainEntity"
              itemtype="https://schema.org/Question"
            >
              <h2 className="centrar" itemprop="name">
                ¿QUIÉNES PUEDEN PARTICIPAR DEL VOLUNTARIADO?
              </h2>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    Todas las personas que lo deseen pueden ser voluntarias pero
                    nuestro enfoque esta en aquellos que estén interesados en el
                    deporte. En Fútbol total todas las personas con sus
                    diferentes credos, origen, culturas y orientación política o
                    sexual, pueden participar sin discriminación.
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div
              itemscope
              itemprop="mainEntity"
              itemtype="https://schema.org/Question"
            >
              <h2 className="centrar" itemprop="name">
                ¿CÓMO PUEDO COLABORAR?
              </h2>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    Ingresá al apartado de Apóyanos y conoce más sobre nuestros
                    proyectos. Puedes realizar donaciones o dar un paso más y
                    ser padrino/ madrina de acuerdo a tus posibilidades.
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div
              itemscope
              itemprop="mainEntity"
              itemtype="https://schema.org/Question"
            >
              <h2 className="centrar" itemprop="name">
                ¿CÓMO SE FINANCIA FÚTBOL TOTAL?{" "}
              </h2>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    Es una tienda virtual sin fines de lucro que busca brindar
                    soluciones deportivas, siendo una herramienta de desarrollo
                    individual, social y económico.
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div
              itemscope
              itemprop="mainEntity"
              itemtype="https://schema.org/Question"
            >
              <h2 className="centrar" itemprop="name">
                ¿QUIÉNES PUEDEN PARTICIPAR DEL VOLUNTARIADO?
              </h2>
              <div
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer"
              >
                <div itemprop="text">
                  <p>
                    Este proyecto es impulsado por la Fundación Proinso y
                    apoyado por sponsors, empresas privadas invitadas y
                    distintas empresas o personas que deciden donar o apadrinar
                    los proyectos.
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <Link to="/terminos-y-condiciones">
            Términos y Condiciones
            </Link>
          </main>
        </Layout>
      </div>
    );
  }
}

export default Faqs;
