import React from "react";
import "../Shopping.css";
import Layout from "../Layout/Layout";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

class Alimentacion extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Nutrición y Deporte</title>

          <meta
            name="description"
            content="Tradicionalmente se cree que la proteína sólo proviene de la carne pero realmente una dieta basada en plantas te ofrece todo  lo que tu cuerpo necesita."
          />

          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="%PUBLIC_URL%imagenes/logof.png" />
          <html lang="es" />
          <meta name="theme-color" content="#000000" />
          <meta name="robots" content="index, follow"/>
        </Helmet>
        <Layout>
        <div itemscope itemtype="https://schema.org/ScholarlyArticle">
          <h1 className="centrar">MEJORÁ TU JUEGO</h1>
          <h2 itemprop="name" className="centrar">ALIMENTACIÓN CONSCIENTE</h2>
          <div className="reglas-j">
            <div className="video-contenidos">
              <ReactPlayer
                width="100%"
                height="100%"
                url="/videos/Habilidad-1.mp4"
                controls
              />
            </div>
            <main>
              <div >
                <p itemprop="description" className="centrar nuestro-juego">
                  <b>¿Sabías que digerir los alimentos requiere más energía que
                  cualquier otra función en el cuerpo humano?</b><br/><br/> Por eso, no es
                  extraño que después de una gran comida, estemos agotados. De
                  hecho, puede estar bien, si somos de los pocos privilegiados
                  que se puede echar una siesta después de comer, para ayudar al
                  cuerpo a recuperarse de tamaño esfuerzo. Pero como por
                  cuestiones de trabajo y de forma de vida, esto no es posible.<br/>
                  La mejor manera de conseguir la energía que necesitamos para
                  el resto del día, será hacer que nuestra digestión sea tan
                  rápida y eficiente como sea posible. <br/><br/><b>¿y cómo podemos agilizar
                  y economizar la energía de la digestión?</b> <br/><br/>Pues muy sencillo,
                  aprendiendo a combinar los alimentos. De esta manera , el
                  sistema digestivo evita un desgaste innecesario y atascos de
                  alimentos que a la larga, llevan al desgaste de la flora
                  bacteriana, el aumento de la permeabilidad intestinal, exceso
                  de putrefacciones intestinales y diferentes intolerancias,
                  alergias y enfermedades.
                </p>
              </div>
            </main>
            <br />
            <br />
            <Link to="/pdf/alimentacion.pdf" target="_blank" download>
              <b><p>Alimentación inteligente PDF descargable</p></b>
            </Link>
          </div>
          </div>
        </Layout>
      </div>
    );
  }
}

export default Alimentacion;
