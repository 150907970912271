import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { facebook } from "../actions/userActions";
import { useHistory,Redirect } from 'react-router-dom';
import "../Shopping.css";

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
 

function Facebook(props) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [id, setId] = useState("");

  const dispatch = useDispatch();
  


    const userSigninFacebook = useSelector((state) => state.userSigninFacebook);

    const { userInfo} = userSigninFacebook;


    const history = useHistory();
   
    const [redirctTo, setRedirctTo] = useState(false);
    
    useEffect(() => {
   
      if (userInfo) {
       window.location.reload(false);
       ////window.location.replace('/');
        // history.push("/");
        // setRedirctTo(true);
        // const redirect =  "/";
        // Location.href=redirect
        
      }
      return () => {};
    }, [userInfo]);

    // if (redirctTo) {
    //   return <Redirect to="/" />;
    // } else {
      
    // }


  const submitHandler = (e) => {
    e.preventDefault();
    //dispatch(facebook(email, id,name));
    
  };




  const respuestaFacebook = (respuesta) => {
    // console.log(respuesta.email);
    // console.log(respuesta.name);
    // console.log(respuesta.id);
    console.log("respuesta",respuesta)
dispatch(facebook(respuesta.email, respuesta.id, respuesta.name));

  }

  return(
    <div>
      <form onSubmit={submitHandler} >

    <FacebookLogin
    cssClass="button-azul-redes"
    appId="385175482715539"
    autoLoad={false}
    fields="name,email,id"
    callback={respuestaFacebook} 
    onChange={(e) => setName(e.target.value),(e) => setEmail(e.target.value),(e) => setId(e.target.value)}
    icon="fa fa-facebook-official"
    render={renderProps => (
      <button onClick={renderProps.onClick} className="button-azul-redes">
              <img
                src="/imagenes/iconos-84.png"
                className="redes-sociales-gmail"
                alt="iniciar sesión con facebook"
              />{" "}
              Continuar con Facebook
            </button>
    )}
    textButton="  Continuar con Facebook"/>
 
          {/*  */}

{/* <div class="fb-login-button" data-size="medium" data-button-type="continue_with" data-layout="default" data-auto-logout-link="true" data-use-continue-as="false" data-width=""></div> */}
</form>
</div>

  );
}
export default Facebook;