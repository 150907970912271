import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { logout } from '../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import "../Shopping.css";
import Layout from './Layout';

function LayoutUsu(props) {
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const dispatch = useDispatch();

    const userSignin = useSelector(state => state.userSignin);
    const { userInfo } = userSignin;
    const handleLogout = () => {
        dispatch(logout());
        
    }
    




    return (
      <div >
        {userInfo ? (
            <div>
              <hr/>
          

           <div className="lista-sidebar">
           <Link to="/mis-compras">
              
              
              <img
                  src="/imagenes/compras-116.png"
                  className="redes-s-salir"
                  alt="Perfil del usuario"
                />
                <p className="saludo"> Mis compras</p>
                
              
              </Link>

              </div>
              

              <br/>
          
          </div>
          
        ) : (
         <br/>
        )}
        
      </div>
    );
}

export default  LayoutUsu;