import React, { Component } from 'react';
import '../Shopping.css';
import Navigation from '../Layout/Navigation';
import Footer2 from '../Layout/Footer2';
import DateRange from '../components/DateRange';
import { Helmet } from "react-helmet";






class DonacionesyP extends Component {

    render(){
    return (
      <div>
        <Helmet>
        <title>Donaciones y padrinazgo</title>

          <meta
            name="description"
            content="Aquí podrás observar con detalle el monto aportado por las personas o instituciones que han confiado en nosotros"
          />

          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="%PUBLIC_URL%imagenes/logof.png" />
          <html lang="es" />
          <meta name="theme-color" content="#000000" />
          <meta name="robots" content="index, follow"/>
        </Helmet>
        <Navigation />
        <div>
          <h1 className="centrar">DONACIONES Y PADRINAZGO </h1>
          <br />
          <h2 className="centrar">Controlá los aportes y verificá el tuyo</h2>
          <div>
            <DateRange />
          </div>{" "}
          <select
            className="filter-fiscalizacion"
            value={this.props.category}
            onChange={(e) =>
              this.props.filterProducts(this.props.products, e.target.value)
            }
          >
            <option value="">Medios de pago</option>
            <option value="inicial">inicial</option>
            <option value="intermedio">intermedio</option>
            <option value="avanzado">avanzado</option>
            <option value="profesional">profesional</option>
          </select>
        </div>
        <div className="search2">
          {" "}
          <i className="fa fa-search"></i>
          <input
            className="filter-fiscalizacion"
            placeholder=" Buscar tu aporte"
            value={this.props.category}
            onChange={(e) =>
              this.props.filterProducts(this.props.products, e.target.value)
            }
          />
        </div>

        <div class="table">
          <table id="superTable" class="display" cellspacing="0" width="100%">
            <thead className="tabla-fondo">
              <tr>
                <th className="tabla">Fecha</th>
                <th className="tabla">Aporte recibido</th>
                <th className="tabla">Medios de pago</th>
              </tr>
            </thead>
          </table>
        </div>
        <main>
        <div class="floatingMenu">
          <ul class="menu vertical">
            <li>
              <a data-mode="true" data-column="0"></a>
            </li>
            <li>
              <a data-mode="true" data-column="1"></a>
            </li>
            <li>
              <a data-mode="true" data-column="2"></a>
            </li>
            <li>
              <a data-mode="true" data-column="3"></a>
            </li>
            <li>
              <a data-mode="true" data-column="4"></a>
            </li>
            <li>
              <a data-mode="true" data-column="5"></a>
            </li>
          </ul>
        </div>
        <br />
        <br />
        <button className="button-red-forms">Imprimir</button>
        <br />
        <br />
        <button className="button-red-forms">PDF</button>
        <br />
        <br />
        <button className="button-red-f">Hoja de cálculo</button>

        <Footer2 />
        </main>
      </div>
    );
  }
  }
  
  export default DonacionesyP;