import React, { Component } from 'react';
import '../Shopping.css';

import { Link } from 'react-router-dom';
import Fade from "react-reveal/Fade";
import Navigation from '../Layout/Navigation';
import Footer2 from '../Layout/Footer2';
import { Helmet } from "react-helmet";






class Fiscalizacionhome extends Component {

    render(){
    return (
      <div>
        <Helmet>
        <title>Fiscalización</title>

          <meta
            name="description"
            content="Una plataforma 100% digital en la que los usuarios podrán monitorear los recursos económicos con transparencia y protagonismo."
          />

          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="%PUBLIC_URL%imagenes/logof.png" />
          <html lang="es" />
          <meta name="theme-color" content="#000000" />
          <meta name="robots" content="index, follow"/>
        </Helmet>
        <Navigation />
        <h1 className="centrar">FISCALIZACIÓN</h1>
        <br />
        <Fade bottom cascade={true}>
          <main>
          <div className="container-fiscalizacion">
            <Link clasName="boton-fiscalizacion" to="/ingresos-egresos">
              <img
                src="/imagenes/ingresos.png"
                className="boton-fiscalizacion"
                alt="INGRESOS Y EGRESO"
              />
            </Link>

            <Link clasName="boton-fiscalizacion" to="/detalles-aporte">
              <img
                src="/imagenes/detalles.png"
                className="boton-fiscalizacion"
                alt="DETALLES DE TU APORTE"
              />
            </Link>

            <Link clasName="boton-fiscalizacion" to="/Donaciones-padrinazgo">
              <img
                src="/imagenes/donaciones.png"
                className="boton-fiscalizacion"
                alt="DONACIONES Y PADRINAZGO"
              />
            </Link>

            <Link clasName="boton-fiscalizacion" to="/existencia-donaciones">
              <img
                src="/imagenes/Existencia.png"
                className="boton-fiscalizacion"
                alt="EXISTENCIA DE DONACIONES"
              />
            </Link>

            <Link clasName="boton-fiscalizacion" to="/entrega-beneficiarios">
              <img
                src="/imagenes/entregas.png"
                className="boton-fiscalizacion"
                alt="ENTREGAS A BENEFICIARIOS"
              />
            </Link>
          </div>
          </main>
        </Fade>
        <Footer2 />
      </div>
    );
  }
  }
  
  export default Fiscalizacionhome;