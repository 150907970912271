import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { register } from "../actions/userActions";
import { Helmet } from "react-helmet";
import Facebook from "../components/Facebook";
import Gmail from "../components/Gmail";
import swal from 'sweetalert';

function RegisterScreen(props) {
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [contrasena, setContrasena] = useState("");
  //const [nueva_contrasena, setNueva_contrasena] = useState("");
  const [documento,setDocumento] = useState("");

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, userInfo, error } = userRegister;
  const dispatch = useDispatch();

  const redirect = props.location.search
    ? props.location.search.split("=")[1]
    : "/signin";
  useEffect(() => {
    if (userInfo) {
      window.location.reload(false);
    }
    return () => {
      //
    };
  }, [userInfo]);

  const submitHandler = (e) => {
    e.preventDefault();
    swal("Ya sos parte de Fútbol total!", "Inicia sesión", "success");
    dispatch(register(nombre, email, contrasena, documento ));
    
  };
  return (
    <section className="login">
      <Helmet>
      <title>Registrate y sé parte se Fútbol total</title>
        <meta
          name="description"
          content="¿Quieres mejorar tu técnica individual con la pelota? ¿Te gustaría aprender inteligentemente? Aquí encontrarás lo necesario..."
        />
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="%PUBLIC_URL%imagenes/logof.png" />
        <html lang="es" />
        <meta name="theme-color" content="#000000" />
        <meta name="robots" content="index, nofollow"></meta>
      </Helmet>
      <Link to="/">
        <button className="close-modal">&times;</button>
      </Link>
      <hr />
      <br />
      <Link to="/">
        <img src="/imagenes/logof.png" className="App-logo" alt="Fútbol total" />
      </Link>
      <br />
      <br />
      <br />
<div className="container-login-form">
      <form onSubmit={submitHandler} >
        <h5 className="centrar">Registrate</h5>
        <div className="datos-forms">
        {loading && <p>Loading...</p>}
              {error && <div className="error">{error}</div>}

          <input
            placeholder="Nombre de usuario"
            className="input"
            type="nombre"
            name="nombre"
            id="nombre"
            onChange={(e) => setNombre(e.target.value)}
            required
          ></input>

          <input
            placeholder="Correo electrónico"
            className="input"
            type="email"
            name="email"
            id="email"
            onChange={(e) => setEmail(e.target.value)}
            required
          ></input>

          <input
            placeholder="Contraseña"
            className="input"
            type="password"
            id="contrasena"
            name="contrasena"
            onChange={(e) => setContrasena(e.target.value)}
            required
          ></input>

          {/* <input
            placeholder="Repetir contraseña"
            className="input"
            type="contrasena"
            id="contrasena"
            name="contrasena"
            onChange={(e) => setNueva_contrasena(e.target.value)}
          ></input> */}
          <input
            placeholder="RUC, CI O pasaporte"
            className="input"
            type="documento"
            id="documento"
            name="documento"
            onChange={(e) => setDocumento(e.target.value)}
            required
          ></input>

          <button type="submit" className="button-red-forms">
            Registrarme y continuar
          </button>

          {/* <br />
          <br />
          <p>o</p>
          <br />
          <br /> */}


         
        </div>
      </form>
      <br />
      {/* <Facebook />

<br />

<Gmail />
<br /> */}
      <Link
            to={redirect === "/" ? "signin" : "signin?redirect=" + redirect}
            className="login-olvidaste"
          >
            ¿Ya tienes cuenta?Inicia sesión
          </Link>
          </div>
    </section>
  );
}
export default RegisterScreen;