import React from 'react';
import Navigation from '../Layout/Navigation';
import Footer from '../Layout/Footer'


const Layout = ({children}) => (
    <div className="App">
        <Navigation />
        {children}
        <Footer />

            <img src="/imagenes/sello.png" className="sello" alt="El sello indica que este proyecto es propiedad de Fundación Proinso" />
            <img src="/imagenes/icc-109.png" className="sello2" alt="whatsapp" />

     
    </div>

);
export default Layout;
