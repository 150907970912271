import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import Modal from "react-modal";
import "../Shopping.css";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { fetchVideos } from "../actions/videosActions";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import Layout from "../Layout/Layout";
import DatosT from "../components/DatosT";
import DatosT3 from "../components/DatosT3";

class Videos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: null,
    };
  }
  
  componentDidMount() {
    
    this.props.fetchVideos();
  }
  componentWillMount() {
    Modal.setAppElement("body");
  }
  openModal = (video) => {
    this.setState({ video });
  };
  closeModal = () => {
    this.setState({ video: null });
  };

  render() {
    const { video } = this.state;
   
    
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      cssEase: "linear",
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <Layout>
        <Helmet>
        <title>Mis cursos online de Fútbol</title>
          <meta
            name="description"
            content="Entrenar tu habilidad individual a cualquier edad, lugar y momento de tu vida AHORA es posible. Mira tus videos diariamente y tu equipo también mejorara."
          />
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="%PUBLIC_URL%imagenes/logof.png" />
          <html lang="es" />
          <meta name="theme-color" content="#000000" />
          <meta name="robots" content="index, follow"></meta>
        </Helmet>
        <div>
          <h1>MIS VIDEOS</h1>

          <Slider {...settings}>
            <div className="container-videos">
              <main>
              <Fade bottom cascade={true}>
                {!this.props.videos ? (
                  <div>Loading...</div>
                ) : (
                  <div>
                    <div className="titulo-video">
                      <h2>NIVEL INICIAL</h2>{" "}
                      <img
                        src="/imagenes/derecha.png"
                        className="logo-flecha-video"
                        alt="reproducir"
                      />
                    </div>{" "}
                    <div className="datos-cursos-container">
                    <DatosT />
                      <DatosT3 />
                    </div>
                    {this.props.videos.data.inicial.map((video) => (
                      <div key={video.id_producto}>
                        <div className="videos-lista">
                          <Link
                            className="videos-lista"
                            to={"#" + video.id_producto}
                            onClick={() => this.openModal(video)}
                          >
                            <div className="video">
                              <p>
                                {" "}
                                {video.name_category} -{video.name_product}
                              </p>

                              <img
                                src="/imagenes/reproducir.png"
                                className="reproducir"
                                alt="reproducir"
                              />
                            </div>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </Fade>
              </main>
            </div>
            <div className="container-videos">
              <Fade bottom cascade={true}>
                {!this.props.videos ? (
                  <div>Loading...</div>
                ) : (
                  <div>
                    <div className="titulo-video">
                      <h2>NIVEL INTERMEDIO</h2>{" "}
                      <img
                        src="/imagenes/derecha.png"
                        className="logo-flecha-video"
                        alt="scroll a la derecha"
                      />
                    </div>
                    <div className="datos-cursos-container">
                      <DatosT />
                      <DatosT3 />
                    </div>
                    {this.props.videos.data.intermedio.map((video) => (
                      <div key={video.id_producto}>
                        <div className="videos-lista">
                          <Link
                            className="videos-lista"
                            to={"#" + video.id_producto}
                            onClick={() => this.openModal(video)}
                          >
                            <div className="video">
                              <p>
                                {" "}
                                {video.name_category} -{video.name_product}
                              </p>

                              <img
                                src="/imagenes/reproducir.png"
                                className="reproducir"
                                alt="reproducir"
                              />
                            </div>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </Fade>
            </div>
            <div className="container-videos">
              <Fade bottom cascade={true}>
                {!this.props.videos ? (
                  <div>Loading...</div>
                ) : (
                  <div>
                    <div className="titulo-video">
                      <h2>NIVEL AVANZADO</h2>{" "}
                      <img
                        src="/imagenes/derecha.png"
                        className="logo-flecha-video"
                        alt="reproducir"
                      />
                    </div>
                    <div className="datos-cursos-container">
                      <DatosT />
                      <DatosT3 />
                    </div>

                    {this.props.videos.data.avanzado.map((video) => (
                      <div key={video.id_producto}>
                        <div className="videos-lista">
                          <Link
                            className="videos-lista"
                            to={"#" + video.id_producto}
                            onClick={() => this.openModal(video)}
                          >
                            <div className="video">
                              <p>
                                {" "}
                                {video.name_category} -{video.name_product}
                              </p>

                              <img
                                src="/imagenes/reproducir.png"
                                className="reproducir"
                                alt="reproducir"
                              />
                            </div>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </Fade>
            </div>

            <div className="container-videos">
              <Fade bottom cascade={true}>
                {!this.props.videos ? (
                  <div>Loading...</div>
                ) : (
                  <div>
                    <div className="titulo-video">
                      <h2>NIVEL PROFESIONAL</h2>{" "}
                      <img
                        src="/imagenes/derecha.png"
                        className="logo-flecha-video"
                        alt="reproducir"
                      />
                    </div>
                    <div className="datos-cursos-container">
                      <DatosT />
                      <DatosT3 />
                    </div>

                    {this.props.videos.data.profesional.map((video) => (
                      <div key={video.id_producto}>
                        <div className="videos-lista">
                          <Link
                            className="videos-lista"
                            to={"#" + video.id_producto}
                            onClick={() => this.openModal(video)}
                          >
                            <div className="video">
                              <p>
                                {" "}
                                {video.name_category} -{video.name_product}
                              </p>

                              <img
                                src="/imagenes/reproducir.png"
                                className="reproducir"
                                alt="reproducir"
                              />
                            </div>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </Fade>

              {video && (
                <Modal
                  className="reproductor"
                  isOpen={true}
                  onRequestClose={this.closeModal}
                >
                  <Zoom>
                    <button className="close-modal" onClick={this.closeModal}>
                      &times;
                    </button>
                    <hr />
                    <div className="video-contenidos">
                        <ReactPlayer
                          width="100%"
                          height="60%"
                          url={video.video}
                          controls
                        />
                      </div>
                    <p>{video.name_product}</p>
                    <div className="reproducir-descripcion">
                      <p>Descripción:{video.description}</p>
                    </div>
                  </Zoom>
                </Modal>
              )}
            </div>
          </Slider>
        </div>
      </Layout>
    );
  }
}
export default connect((state) => ({ videos: state.videos.filteredItems }), {
  fetchVideos,
})(Videos);
