import React, {Component} from'react';
import '../Shopping.css';
import Layout from '../Layout/Layout';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';



class QuienesSomos extends Component {
    render(){
    return (
      <div>
        <Helmet>
        <title>Fútbol total de la mano de Fundación proinso</title>

          <meta
            name="description"
            content="Con nosotros cualquiera puede ser delantero, centrocampista y defensa. Encontrarás desde lo más básico para empezar a jugar fútbol hasta lo más avanzado."
          />

          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="%PUBLIC_URL%imagenes/logof.png" />
          <html lang="es" />
          <meta name="theme-color" content="#000000" />
          <meta name="robots" content="index, follow"></meta>
        </Helmet>
        <Layout>
          <h1 className="centrar">¿QUIÉNES SOMOS?</h1>
          <main>
          <div>
            <p className="container-qs">
              Fútbol Total es un proyecto impulsado por la Fundación Proinso,
              inspirado en el Método Coerver (Wiel Coerver), en la periodización
              táctica (Victor Frade) y en el entrenamiento estructurado de Paco
              Seirulo, apoyado en la Teoría de las Emociones del Dr. Antonio
              Damasio, proponiendo actividades y herramientas apoyadas en la
              tecnología, que buscar incidir sobre aspecto técnicos, tácticos,
              físicos y en la formación integral del jugador/a en relación a los
              valores a través del deporte.
            </p>
          </div>
          </main>
          <div className="container-login-form-QS">
            <h2 className="centrar">VISIÓN</h2>
            <p>
              Ser una plataforma digital que brinde soluciones deportivas,
              siendo una herramienta de desarrollo individual, social y
              económico.
            </p>
          </div>
          <div className="container-login-form-QS">
            <h2 className="centrar">MISIÓN</h2>
            <p>
              Crear mejor ciudadanos a través del deporte y la tecnología como
              plataforma de formación y de comercialización, asumiendo un
              enfoque desde lo individual a lo colectivo, enfatizando el dominio
              de la pelota y el 1 vs 1, innovando constantemente, con
              gerenciamiento económico sano, abocados en la participación,
              transparencia, e inclusión social.
            </p>
          </div>

          <div className="container-login-form-QS">
            <h2 className="centrar">VALORES</h2>
            <p>
              <li>Capacitación Constante.</li>
              <li>Innovación tecnológica.</li>
              <li>Desarrollo social y económico</li>
            </p>
          </div>
          <Link className="login-olvidaste" to="/preguntas-frecuentes"><p>Preguntas frecuentes</p></Link>
          
        </Layout>
      </div>
    );
}
}

export default QuienesSomos;