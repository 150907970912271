import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { logout } from '../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import "../Shopping.css";

function MisDatos2(props) {
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const dispatch = useDispatch();

    const userSignin = useSelector(state => state.userSignin);
    const { userInfo } = userSignin;
    const handleLogout = () => {
        dispatch(logout());
        
    }
    




    return (
      <div >
        {userInfo ? (
            
          <Link className="lista-sidebar-700-iz" to="/perfil">
             
              <p>Mis datos</p>
            </Link>
            
              
         
         
          
          
        ) : (
         <div className="md"></div>
        )}
        
      </div>
    );
}

export default  MisDatos2;