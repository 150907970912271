import React, { Component } from "react";
import "../Shopping.css";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import Layout from "../Layout/Layout";
import { connect } from "react-redux";
import { fetchProjects } from "../actions/ProjectActions";
import Modal from "react-modal";
import Zoom from "react-reveal/Zoom";
import DatosT2 from "../components/DatosT2";
import ReactPlayer from "react-player";
import { Helmet } from "react-helmet";

class Padrinazgo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: null,
    };
  }
  componentWillMount() {
    Modal.setAppElement("body");
  }
  componentDidMount() {
    this.props.fetchProjects();
  }
  openModal = (project) => {
    this.setState({ project });
  };
  closeModal = () => {
    this.setState({ project: null });
  };

  render() {
    const { project } = this.state;
    const checkoutHandler = () => {
      this.props.history.push("/padrinazgo-form/:id");
    };
    return (
      <Layout>
        <Helmet>
          <title>Padrinazgo</title>

          <meta
            name="description"
            content="Ser padrino significa ser el corazón de un proyecto con inclusión social de una población vulnerable, implica un aporte mensual en base a tus posibilidades"
          />

          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="%PUBLIC_URL%imagenes/logof.png" />
          <html lang="es" />
          <meta name="theme-color" content="#000000" />
          <meta name="robots" content="index, follow"/>
        </Helmet>
        <h1 className="centrar">APADRINÁ</h1>
        <br />
        <main>
          <Fade bottom cascade={true}>
            {!this.props.projects ? (
              <div>Loading...</div>
            ) : (
              <div>
                {this.props.projects.patronage.map((project) => (
                  <div key={project.id_proyecto}>
                    <div className="contenedor-rendimiento ">
                      <div className="imagen-rectangular-dentro">
                        <h2 className="centrar">{project.name}</h2>
                        <Link
                          className="link-name"
                          to={`/padrinazgo-form/${project.id_proyecto}`}
                          onClick={() => this.openModal(project)}
                        >
                          <img
                            className="imagen-logo-dentro"
                            src={project.image}
                            alt={project.name}
                          />
                        </Link>
                        <br />
                        <div className="datos-t">
                          <div className="datos_tiempo">
                            <p className="datos-m">
                              Cantidad de donantes: {project.c_donantes}
                            </p>
                            <p className="datos-m">Meta: Gs{project.meta}</p>
                            <p className="datos-m">
                              Monto recaudado: Gs{project.progress}
                            </p>
                          </div>
                          <div>
                            <DatosT2
                              meta={project.meta}
                              monto_recaudado={project.progress}
                            />
                          </div>
                        </div>

                        <div className="product-price-t">
                          {project.summary_description}
                        </div>
                        <br />
                        <Link
                          to={`/padrinazgo-form/${project.id_proyecto}`}
                          // to={"#" + project.id_proyecto}
                        >
                          <button
                            //onClick={`/padrinazgo-form/${project.id_project}`}
                            className="button-red-forms"
                          >
                            Apadriná
                          </button>
                        </Link>
                        <br />
                        <br />
                        <Link
                          // to={"#" + project.id_proyecto}
                          onClick={() => this.openModal(project)}
                        >
                          <button className="button-transparente-forms">
                            Conocer más
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}

                {project && (
                  <div className="centrar">
                    <Modal
                      className="my-modal"
                      isOpen={true}
                      onRequestClose={this.closeModal}
                    >
                      <Zoom>
                        <button
                          className="close-modal"
                          onClick={this.closeModal}
                        >
                          &times;
                        </button>
                        <hr />
                        <div key={project.id_proyecto}>
                          <h1 className="centrar">APADRINÁ</h1>
                          <h2 className="centrar">{project.name}</h2>
                          <div className="video-contenidos">
                            <ReactPlayer
                              width="100%"
                              height="100%"
                              url={project.video}
                              controls
                            />
                          </div>
                          <br />

                          <div className="product-price-t">
                            {project.description}
                          </div>
                          <div>
                            <br />
                            <br />

                            <div className="product-price centrar">
                              <Link
                                to={`/padrinazgo-form/${project.id_proyecto}`}
                                // to={"#" + project.id_proyecto}
                              >
                                <button
                                  //onClick={`/padrinazgo-form/${project.id_project}`}
                                  className="button-red-forms"
                                >
                                  Apadriná
                                </button>
                              </Link>
                              <br />
                              <br />
                              <button
                                className="button-transparente-forms"
                                onClick={() => {
                                  this.closeModal();
                                }}
                              >
                                Volver
                              </button>
                              <br />
                              <br />
                            </div>
                          </div>
                        </div>
                      </Zoom>
                    </Modal>
                  </div>
                )}
              </div>
            )}
          </Fade>
        </main>
        <div className="container-sello">
          <img src="/imagenes/sello.png" className="sello"  alt="El sello indica que este proyecto es propiedad de Fundación Proinso"/>
        </div>
      </Layout>
    );
  }
}
export default connect(
  (state) => ({
    projects: state.projects.items,
  }),
  {
    fetchProjects,
  }
)(Padrinazgo);
