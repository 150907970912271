import React, {Component} from 'react';
import '../Shopping.css';


class Footer extends Component {
  render(){
  return (
<div className="site-footer">
<div className="footer"><b>Fundación Proinso.</b>
<br/>Desarrollado y donado por <b>QUATTRO S.A</b>
</div>

</div>
  


);
}
}

 export default Footer;